import React, { useContext } from 'react';
import { ProductDetailsContext } from './ProductDetailsContext';
import MinusSVG from '../../shared/icons/MinusSVG';
import PlusSVG from '../../shared/icons/PlusSVG';

const ProductDetailsQuantityInput = ({ id, name, setSelectedQuantity }) => {
	const productDetailsProps = useContext(ProductDetailsContext);
	const productQuantity = productDetailsProps?.quantitySelected;

	const decrementQuantity = () => {
		if (productQuantity > 1) {
			const newQuantity = productQuantity - 1;
			productDetailsProps.changeQuantity(newQuantity);
			setSelectedQuantity(newQuantity)
		}
	};

	const incrementQuantity = () => {
		const newQuantity = productQuantity + 1
		productDetailsProps.changeQuantity(newQuantity);
		setSelectedQuantity(newQuantity)
	};

	const handleChange = (e) => {
		const newQuantity = parseInt(e.currentTarget.value);

		if (!isNaN(newQuantity) || newQuantity >= 1) {
			productDetailsProps.changeQuantity(newQuantity);
			setSelectedQuantity(newQuantity)
		}
	};

	return (
		<div className="productDetails__products-box-inputs-number">
				<button className="productDetails__products-box-inputs-number-btn productDetails__products-box-inputs-number-decrement" onClick={decrementQuantity} aria-label="Decrement Product Quantity" style={{fontSize:"18px !important"}}>
					-
				</button>
				<input
					type="text"
					id={id}
					name={name}
					className="productDetails__products-box-inputs-number-input input-number"
					// style={{minWidth:"0 !important"}}
					value={productQuantity}
					onChange={handleChange}
					min={1}
				/>
				<button className="productDetails__products-box-inputs-number-btn productDetails__products-box-inputs-number-increment" onClick={incrementQuantity} aria-label="Increment Product Quantity">
					+
				</button>
		</div>
	);
};

export default ProductDetailsQuantityInput;
