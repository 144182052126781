import * as R from "ramda";
import initialSidebar from "../components/MyAccount/sidebar.json";
import {
  TOGGLE_MENU_DROPDOWN,
  SET_ACTIVE_MENU,
  TOGGLE_HAMBURGER_MENU,
  SET_CONTACT_PROPS,
  SET_USER_NAME_AVAILABILITY,
  SET_CREDIT_CARD_PROP,
  UPDATE_CREDIT_CARD,
  SET_ADDRESS_BOOK_PROP,
  SET_SALESREP,
  SET_IS_SIDEBAR_COLLAPSED,
  SET_OVERVIEW_DATA,
  SET_SALES_ORDERS,
} from "../actions/types";

const INITIAL_STATE = {
  creditCard: {
    creditCardType: {},
    cards: [],
    card: {},
    focusedField: "",
    type: "",
    isValid: "",
    message: { type: "", message: "" },
    modal: false,
  },
  addressBook: {
    addresses: [],
    address: {},
    message: { type: "", message: "" },
    modal: false,
    defaultShipping: "", //1901981,
    defaultBilling: "", //1903881,
    dddShippingCutoffTime: "",
  },
  hamburgerToggle: false,
  activeMenu: "",
  menu: initialSidebar,
  contact: {
    record: null,
    mode: "create",
    modal: false,
    isUsernameAvailable: null,
    // message: ''
  },
  salesrep: {},
  salesorders: [],
  orderNumbers: "",
  isSidebarCollapsed: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_SALES_ORDERS:
      // console.log(payload) TO keep, do not remove. Useful for troubleshooting.
      return { ...state, salesorders: payload };
    case TOGGLE_MENU_DROPDOWN:
      return {
        ...state,
        menu: R.mergeRight(state.menu, {
          [payload]: {
            ...state.menu[payload],
            open: !state.menu[payload].open,
          },
        }),
      };
    case SET_ACTIVE_MENU:
      return { ...state, activeMenu: payload };
    case TOGGLE_HAMBURGER_MENU:
      return { ...state, hamburgerToggle: !state.hamburgerToggle };
    case SET_CONTACT_PROPS:
      return { ...state, contact: { ...state.contact, ...payload } };
    case SET_USER_NAME_AVAILABILITY:
      return {
        ...state,
        contact: { ...state.contact, isUsernameAvailable: payload },
      };
    case SET_CREDIT_CARD_PROP:
      return {
        ...state,
        creditCard: {
          ...{ ...state.creditCard, [payload.prop]: payload.data },
        },
      };
    case UPDATE_CREDIT_CARD:
      return {
        ...state,
        creditCard: {
          ...{
            ...state.creditCard,
            cards: state.creditCard.cards
              .filter((f) => f.id !== payload.id)
              .concat(payload),
          },
        },
      };
    case SET_ADDRESS_BOOK_PROP:
      return {
        ...state,
        addressBook: {
          ...{ ...state.addressBook, [payload.prop]: payload.data },
        },
      };
    case SET_SALESREP:
      return { ...state, salesrep: payload };
    case SET_OVERVIEW_DATA:
      return { ...state, orderNumbers: payload };
    case SET_IS_SIDEBAR_COLLAPSED:
      return { ...state, isSidebarCollapsed: payload === undefined ? !state.isSidebarCollapsed :  !payload};
    default:
      return state;
  }
};
