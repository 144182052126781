import React from 'react';
import { isEmpty } from 'ramda';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const ProductDetailsBreadcrumbs = ({ props }) => {
    const productName = props.product.data.displayName
    const categoryLabel = props.product.data.category?.parent?.name
    const categoryId = props.product.data.category?.parent?.id
    const subCategoryId = props.product.data.category?.id
    const categoryUrl = props.product.data.category?.parent?.urlComponent|| categoryId
    const subcatLabel = props.product.data.category?.name
    const subcategory = props.product.data.category
    const subcategoryUrl = props.product.data.category?.urlComponent|| subCategoryId
    const crumbs = subcategory && !isEmpty(subcategory)
			? [
					{ label: categoryLabel, path: `/category/${categoryUrl}` },
					{ label: subcatLabel, path: `/products/1?category=${subcategoryUrl}` },
			  ]
			: [
                    { label: categoryLabel, path: `/products/1?category=${subcategoryUrl}` }
              ];        
            
    if(categoryId){
        return ( <Breadcrumbs crumbs={crumbs} isPDP= {true} />)
    }else{
        return (null);
    }
   
}

export default ProductDetailsBreadcrumbs;

