import React, { useContext } from 'react';
import { ProductDetailsContext } from './ProductDetailsContext';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { queryStringWithRedirect } from '../../utils/shopping'

const ProductDetailsLoginButton = () => {
    const productDetailsProps = useContext(ProductDetailsContext);

    const currentPath = pathOr('', ['location', 'pathname'], productDetailsProps);
    const currentQuery = pathOr('', ['location', 'search'], productDetailsProps);

    return (
        <Link 
            to={{
                pathname: '/login',
                search: queryStringWithRedirect(currentPath, currentQuery),
            }}
            className="product-details__login-button"
        >
            Login to View Price
        </Link>
    );
}

export default ProductDetailsLoginButton;
