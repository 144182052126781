import { SET_OPTIONS, SET_ALL_SETTINGS_OPTIONS, SET_INITIAL_VALUES } from '../actions/types'

const INITIAL_STATE = {
    allSettingsOptions: {},
    statementPreferences: {
        statementFrequency: [],
        statementMethod: [],
        statementIncludeInvoices: []
    },
    accountPreferences: {
        approvalsNotification: ''
    },
    initialValues: {}
}


export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_OPTIONS:
            return { ...state, [payload.reducerProp]: { ...state[payload.reducerProp], ...payload.optionsToSet } }

        // forEachObjIndexed((value, key) => {
        //     [payload.reducerProp][key] = value
        // }, payload.optionsToSet)

        case SET_ALL_SETTINGS_OPTIONS:
            return { ...state, allSettingsOptions: payload }

        case SET_INITIAL_VALUES:
            return { ...state, initialValues: payload }

        default:
            return state
    }
}

