import React from 'react';
import { addDefaultSrc } from '../../utils';
import classNames from 'classnames';

const ProductDetailsThumb = React.memo(({ thumbImageSrc, isSelected, setProductImage }) => {
    const productDetailsThumbButtonClasses = classNames({
        "product-details__thumb-button": true,
        "product-details__thumb-button--selected": isSelected
    });

    const productDetailsThumbImageClasses = classNames({
        "product-details__thumb-image": true,
        "product-details__thumb-image--selected": isSelected
    });

    const handleClick = () => {
        setProductImage(thumbImageSrc);
    }

    return (
        <li className="product-details__thumb">
            <button className={ productDetailsThumbButtonClasses } onClick={ handleClick }>
                <img 
                    src={ thumbImageSrc } 
                    className={ productDetailsThumbImageClasses } 
                    onError={ addDefaultSrc } 
                    alt="Product Image"
                    loading="lazy" 
                />
            </button>
        </li>
    );
});

export default ProductDetailsThumb;
