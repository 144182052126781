import React, { useState } from 'react'
import { Popover, PopoverBody } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import reorderIcon from './reorder.png'

const ReorderButton = () => {
    const [popoverIsOpen, openPopover] = useState(false)
    return (
        <>
            <Link to='/myaccount/menu/reorder' id="reorderIcon">
                <img src={reorderIcon} alt='reorder' className='reorder-icon' loading="lazy"/>
            </Link>
            <Popover
                className="reorder-button-popover"
                target="reorderIcon"
                isOpen={popoverIsOpen}
                toggle={()=>openPopover(!popoverIsOpen)}
                trigger="hover"
                placement="auto"
                delay={150}
            >
            <PopoverBody>Reorder</PopoverBody>  
            </Popover>
        </>
    );
}

export default withRouter(ReorderButton);