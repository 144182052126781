import { createContentfulClient } from './createContentfulClient'

export const useContentful = () => {
    const client = createContentfulClient()

    const getWebsiteData = async () => {
        try {
            const entries = await client.getEntries({
                content_type: 'websiteContent',
                limit:1, 
                include:6           
            })

            return entries
        } catch (error) {
            console.log(`Error fetching Homepage data: ${error}`)
        }
    }

    const getEntryData = async (entryId) => {
        return client.getEntry(entryId)
    }

    return {
      getWebsiteData,
      getEntryData
    };
}