import React from 'react';
import classnames from 'classnames';

const CheckmarkSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 24 24" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M10.852 0.052c-2.798 0.286-5.348 1.491-7.327 3.464-4.697 4.688-4.697 12.281 0 16.969 1.903 1.898 4.223 3.038 6.998 3.431 0.558 0.080 2.4 0.075 2.977-0.005 2.714-0.375 5.109-1.552 6.98-3.431 1.355-1.359 2.32-2.934 2.911-4.73 0.375-1.139 0.548-2.1 0.595-3.328 0.122-3.333-1.134-6.53-3.511-8.906-1.842-1.847-4.177-3.014-6.811-3.403-0.572-0.084-2.236-0.122-2.813-0.061zM18.806 6.572c0.337 0.159 0.666 0.483 0.82 0.82 0.234 0.516 0.188 1.195-0.112 1.641-0.108 0.155-5.527 5.714-7.533 7.73-0.689 0.689-0.895 0.792-1.552 0.792-0.806 0-0.675 0.108-3.544-2.775-2.803-2.813-2.644-2.606-2.644-3.389 0-0.398 0.014-0.473 0.141-0.717 0.173-0.352 0.548-0.698 0.905-0.834 0.37-0.145 0.867-0.145 1.252 0.005 0.262 0.098 0.398 0.22 1.828 1.645 0.853 0.844 1.608 1.556 1.678 1.584 0.169 0.066 0.492 0.066 0.68 0 0.094-0.033 1.181-1.102 3.197-3.15 1.683-1.706 3.131-3.155 3.225-3.216 0.464-0.319 1.144-0.37 1.659-0.136z"></path>
		</svg>
	);
};

export default CheckmarkSVG;
