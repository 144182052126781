import { pathOr } from 'ramda'
import {
    SET_PENDING_APPROVAL_ORDERS, SET_REJECTED_ORDERS, REMOVE_APPROVAL_ORDER, ADD_TO_REJECTED_ORDERS,
    SET_SELECTED_APPROVAL_ORDER_DETAILS, SET_REJECT_ORDER_MESSAGE, SET_SELECTED_CREDIT_CARD, CHANGE_SELECTED_APPROVAL_ORDER_FIELD,
    RESET_ORDER_APPROVAL_FORM
} from '../actions/types'

const INITIAL_STATE = {
    pendingApprovalOrders: [],
    rejectedOrders: [],
    selectedOrderDetails: {},
    orderApprovalForm: {
        shipAddressId: '',
        billAddressId: '',
        poNum: '',
        orderConfirmationId: '',
        paymentMethod: {}
    },
    isLoading: true
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case SET_PENDING_APPROVAL_ORDERS:
            return { ...state, pendingApprovalOrders: payload }

        case SET_REJECTED_ORDERS:
            return { ...state, rejectedOrders: payload }

        case REMOVE_APPROVAL_ORDER:
            return { ...state, [payload.reducerProp]: state[payload.reducerProp].filter(p => p.id !== payload.id) }

        case ADD_TO_REJECTED_ORDERS:
            return { ...state, rejectedOrders: state.rejectedOrders.concat([{ ...payload, status: 'Rejected', reducerProp: 'rejectedOrders' }]) }

        case SET_SELECTED_APPROVAL_ORDER_DETAILS:
            return { ...state, selectedOrderDetails: payload }

        case SET_SELECTED_CREDIT_CARD:
            return { ...state, selectedCreditCard: pathOr([], ['creditCards'], payload).find(f => f.id == payload.id) || {} }

        case SET_REJECT_ORDER_MESSAGE:
            return { ...state, selectedOrderDetails: { ...state.selectedOrderDetails, rejectedMessage: payload } }

        case CHANGE_SELECTED_APPROVAL_ORDER_FIELD:
            return {
                ...state, orderApprovalForm:
                    { ...state.orderApprovalForm, [payload.field]: payload.value }
            }

        case RESET_ORDER_APPROVAL_FORM:
            return { ...state, orderApprovalForm: INITIAL_STATE.orderApprovalForm }

        default:
            return state
    }
}
