import * as R from 'ramda'

export const getValidUOM = (unit = '', units = []) => {

    if (R.isNil(units))
        return {}

    const validUOM = R.find(R.propEq(unit, 'id'), units)
    const baseUnit = R.find(R.propEq(1, 'baseUnit'), units)
    return validUOM ? validUOM : !validUOM && baseUnit ? baseUnit : {}
}

export const getUOMConversionRate = (unit = '', units = []) => {

    if (R.isNil(units))
        return 1

    const validUOM = R.find(R.propEq(unit, 'id'), units)
    const baseUnit = R.find(R.propEq(1, 'baseUnit'), units)
    return validUOM ? validUOM.conversionRate : !validUOM && baseUnit ? baseUnit.conversionRate : 1
}

export const uomOptionsToLabelValue = R.map(({ id: value, unitName: label }) => ({ value, label }))
