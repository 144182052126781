import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import algoliasearch from 'algoliasearch'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ProductHOC from '../Reusable/ProductHOC'
import { Autocomplete } from './Autocomplete'
import { ShoppingContext } from '../../shared/ShoppingContext'
import '@algolia/autocomplete-theme-classic/dist/theme.css'
import './SearchbarNew.scss'

// export const INSTANT_SEARCH_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX
// export const INSTANT_SEARCH_QUERY_SUGGESTIONS = process.env.REACT_APP_ALGOLIA_INDEX_NAME

const searchClient = algoliasearch('9487DJSWRC', process.env.REACT_APP_ALGOLIA_API_KEY)

export function SearchbarNew(props) {
  const [searchState, setSearchState] = useState({})

  const { setFacetsForFiltering } = useContext(ShoppingContext)

  const onSubmit = useCallback(({ state }) => {
    setSearchState(searchState => ({
      ...searchState,
      query: state.query,
    }))
    props.onChange(state.query)
    props.onClick(state.query)
    setFacetsForFiltering({})
  }, [])

  const onPluginSubmit = useCallback(query => {
    setSearchState(searchState => ({
      ...searchState,
      query: query,
    }))
    setFacetsForFiltering({})
    props.onChange(query)
    props.onClick(query)
  }, [])

  const onReset = useCallback(() => {
    setSearchState(searchState => ({
      ...searchState,
      query: '',
    }))
  }, [])

  const plugins = useMemo(() => {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          //onSelect performs actions on the previous search suggestions
          onSelect({ item }) {
            setSearchState(searchState => ({
              ...searchState,
              query: item.label,
            }))
            onPluginSubmit(item.label)
          },
        }
      },
    })

    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: 'itemsIndex_query_suggestions',
      getSearchParams() {
        return {
          hitsPerPage: 5,
        }
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          //onSelect performs actions on the query suggestions
          onSelect({ item }) {
            setSearchState(searchState => ({
              ...searchState,
              query: item.query,
            }))
            onPluginSubmit(item.query)
          },
        }
      },
    })

    return [recentSearchesPlugin, querySuggestionsPlugin]
  }, [])

  return (
    <div>
      <Autocomplete
        placeholder="Search products"
        detachedMediaQuery="none"
        initialState={{
          query: searchState.query,
        }}
        openOnFocus={true}
        onSubmit={onSubmit}
        onReset={onReset}
        plugins={plugins}
      />
    </div>
  )
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}
export default withRouter(connect(mapStateToProps, null)(ProductHOC(SearchbarNew)))
