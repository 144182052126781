import React from 'react';
import classnames from 'classnames';

const ClockSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 32 32" className={classnames('dealmed-icon dealmed-clock-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M16.688 9.313v7l6 3.563-1 1.688-7-4.25v-8h2zM16 26.688q4.375 0 7.531-3.156t3.156-7.531-3.156-7.531-7.531-3.156-7.531 3.156-3.156 7.531 3.156 7.531 7.531 3.156zM16 2.688q5.5 0 9.406 3.906t3.906 9.406-3.906 9.406-9.406 3.906-9.406-3.906-3.906-9.406 3.906-9.406 9.406-3.906z"></path>
		</svg>
	);
};

export default ClockSVG;
