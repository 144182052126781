
import { mergeRight } from 'ramda'
import qs from 'qs'
import { resolveReq, axios } from '../utils'
import { SET_PAGINATE_FIELD, START_LOADER, STOP_LOADER, CONCAT_TO_PRODUCTS } from './types'

export const setPaginationFields = ({ reducerProp, data }) => {
    return dispatch => {
        dispatch({
            type: SET_PAGINATE_FIELD,
            payload: {
                reducerProp, data
            }
        })
    }
}

export const getPaged = (params, { route, reducerProp, loader }) => {
    const { page } = params
    return async dispatch => {
        const str = qs.stringify(params, { addQueryPrefix: true, indices: false, arrayFormat: 'repeat', allowDots: true })
        if (page)
            setPaginationFields({ reducerProp, data: { page, isLoading: true } })(dispatch)
        dispatch({ type: START_LOADER, payload: loader })

        const { data, error } = await resolveReq(axios.get(`/api/${route}/paginate` + str))
        if (error) {
            dispatch({ type: STOP_LOADER, payload: loader })
            // eslint-disable-next-line no-console
            return console.log(error)
        }
        setPaginationFields({ reducerProp, data: mergeRight(data, { isLoading: false }) })(dispatch)
        dispatch({ type: STOP_LOADER, payload: loader })
    }
}

const isEmptyFacets = (facets) => {
    if (!facets) return true; // Check if falsy
    if (Object.keys(facets).length === 0) return true; // Check if empty object
    if (Object.keys(facets).some(key => Array.isArray(facets[key]) && facets[key].length === 0)) return true; // Check if it has an array property with length 0
    return false;
};

/////Does the exact same thing as the getPaged except works through a post request instead of a get request
export const getPagedPost = (params, { route, reducerProp, loader }) => {
    const { page } = params
    return async dispatch => {
        // Check if facets are empty
        const facetsAreEmpty = isEmptyFacets(params.facets);

        if (page) {
            setPaginationFields({
                reducerProp,
                data: {
                    page,
                    isLoading: facetsAreEmpty
                }
            })(dispatch);
        }

        if (facetsAreEmpty) {
            dispatch({ type: START_LOADER, payload: loader });
        }

        const { data, error } = await resolveReq(axios.post(`/api/${route}/paginate`, params))
        if (error) // eslint-disable-next-line no-console
            return console.log(error)

        setPaginationFields({ reducerProp, data: mergeRight(data, { isLoading: false }) })(dispatch)
        dispatch({ type: STOP_LOADER, payload: loader })
    }
}


////Used for adding onto original retrieved data instead of overwritting it
export const getMorePagedProducts = (params) => {
    const { page } = params
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'isLoadingMoreProducts' })
        const { data, error } = await resolveReq(axios.post(`/api/shopping/item/paginate`, params))
        if (error) // eslint-disable-next-line no-console
            return console.log(error)
        dispatch({ type: CONCAT_TO_PRODUCTS, payload: { ...data, page } })
        dispatch({ type: STOP_LOADER, payload: 'isLoadingMoreProducts' })
    }
}