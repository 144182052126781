import React from 'react';
import * as R from 'ramda';

const ProductSchemaMarkup =({ productDetailsProps, productImgUrl }) => {
	const selectItem = (children, id) => R.find((f) => id == f.urlComponent || id == f.id)(children) || children[0];

	let id = productDetailsProps.match?.params.id;

	const product =
	productDetailsProps.product.data.children && productDetailsProps.product.data.children.length > 0
		? R.mergeDeepRight(productDetailsProps.product.data, R.omit(['description'], selectItem(productDetailsProps.product.data.children, id)))
		: productDetailsProps.product.data;

	const displayName = R.pathOr('', ['product', 'data', 'displayName'], productDetailsProps);
	const description = R.pathOr('', ['product', 'data', 'description'], productDetailsProps);
	const manufacturer = R.pathOr('', ['product', 'data', 'manufacturer'], productDetailsProps);
	const mpn = R.pathOr('', ['product', 'data', 'mpn'], productDetailsProps);
	const itemName = R.pathOr('', ['product', 'data', 'itemName'], productDetailsProps);
	const thumbnailImage = R.pathOr('', ['product', 'data', 'thumbnailImage'], productDetailsProps);

	const productStructuredData = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"name": product.displayName,
		"image": productImgUrl + thumbnailImage,
		"description": product.description,
		"brand": product.manufacturer,
		"mpn": product.mpn,
		"sku": product.itemName
		};

	return (
		<div className="productStructuredData">
      		<script type="application/ld+json">
        		{JSON.stringify(productStructuredData)}
			</script>
	  </div>
	);
};

export default ProductSchemaMarkup;