import {
    TOGGLE_LOGIN_VIEW, SET_USER_AUTH, SET_IS_PASSWORD_RESET_VALID, SET_AUTH_ALERT,
    SET_IS_VALID_TOKEN, SET_RELATED_ACCOUNTS, SET_SELECTED_RELATED_ACCOUNT, GET_BUSINESS_TYPES, GET_CONTACT_ROLES
} from '../actions/types'

const INITAL_STATE = {
    viewLogin: 'login',
    isValidToken: false,
    user: null,
    isPasswordResetValid: null,
    alert: { message: '', type: null },
    relatedAccounts: [],
    selectedRelatedAccount: null,
    businessTypes: [],
    contactRoles: []
}
// return { type: SET_AUTH_ALERT, payload }
export default (state = INITAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER_AUTH:
            return { ...state, user: payload, isValidToken: false }

        case SET_IS_VALID_TOKEN:
            return { ...state, isValidToken: payload }

        case TOGGLE_LOGIN_VIEW:
            return { ...state, viewLogin: payload }

        case SET_IS_PASSWORD_RESET_VALID:
            return { ...state, isPasswordResetValid: payload }

        case SET_AUTH_ALERT:
            return { ...state, alert: payload }

        case SET_RELATED_ACCOUNTS:
            return { ...state, relatedAccounts: payload }
        case SET_SELECTED_RELATED_ACCOUNT:
            return { ...state, selectedRelatedAccount: payload }
        case GET_BUSINESS_TYPES:
            return { ...state, businessTypes: payload }
        case GET_CONTACT_ROLES:
            return { ...state, contactRoles: payload }
        default:
            return state
    }
}