import React from 'react';
import classnames from 'classnames';

const DealmedLogoSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 43 44" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<defs>
				<linearGradient x1="631.09045%" y1="629.068947%" x2="632.374096%" y2="629.068947%" id="dealmed-logo-svg-linear-gradient">
					<stop stopColor="#8F1E39" offset="0%"></stop>
					<stop stopColor="#8F1E39" offset="0.0455097004%"></stop>
					<stop stopColor="#BB0C33" offset="100%"></stop>
				</linearGradient>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<g>
						<mask id="dealmed-logo-svg-mask" fill="white">
							<path d="M9.15524292,28.6871948 C9.15524292,25.7672424 11.5312805,23.3912048 14.4512024,23.3912048 L19.7477112,23.3912048 L19.7477112,28.6871948 C19.7477112,31.607666 17.3717041,33.9842529 14.4512024,33.9842529 C11.5312805,33.9842529 9.15524292,31.607666 9.15524292,28.6871948 M19.7477112,4.57733154 L19.7477112,14.2365112 L14.4512024,14.2365112 C6.48287964,14.2365112 0,20.7193909 0,28.6871948 C0,36.6560669 6.48287964,43.1389465 14.4512024,43.1389465 C22.4200745,43.1389465 28.9029846,36.6560669 28.9029846,28.6871948 L28.9029846,23.3912048 L38.0916443,23.3912048 C40.6194153,23.3912048 42.6690063,21.3421631 42.6690063,18.8138428 C42.6690063,16.2855225 40.6194153,14.2365112 38.0916443,14.2365112 L28.9029846,14.2365112 L28.9029846,4.57733154 C28.9029846,2.04956055 26.8533936,0 24.3251038,0 C21.7967834,0 19.7477112,2.04956055 19.7477112,4.57733154"></path>
						</mask>
						<rect
							fill="url(#dealmed-logo-svg-linear-gradient)"
							fillRule="nonzero"
							mask="url(#dealmed-logo-svg-mask)"
							x="-0.000234014122"
							y="0"
							width="42.6690102"
							height="43.1389656"
						></rect>
					</g>
					<path d="M19.748,23.392 L28.9032734,23.392 L19.748,26.0638445 L19.748,23.392 Z" fill="#1A1818" fillRule="nonzero"></path>
				</g>
			</g>
		</svg>
	);
};

export default DealmedLogoSVG;
