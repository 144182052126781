import React from 'react';
import ProductDetailsRelatedProductsSlider from './ProductDetailsRelatedProductsSlider';

const ProductDetailsRelatedProducts = React.memo(({ productDetailsProps }) => {
	return (
		<div className="product-details__related-products">
			<h2 className="product-details__tab-title" id="related-products">
				Related Products
			</h2>

			<ProductDetailsRelatedProductsSlider productDetailsProps={productDetailsProps} sliderInstanceId="related-products-slider" />

			<div className="product-details__related-products-controls">
				<button className="product-details__related-products-control product-details__related-products-control--prev">
					<span className="visually-hidden">Previous Slide</span>
				</button>
				<button className="product-details__related-products-control product-details__related-products-control--next">
					<span className="visually-hidden">Next Slide</span>
				</button>
			</div>
		</div>
	);
});

export default ProductDetailsRelatedProducts;
