/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
    calculateTax,
    calculateConvenienceFee,
    resetQuantityInput,
    setCartDetails,
    editCartDetail,
    removeCartDetail,
    toggleCartPopover,
    editQuantityInput,
    moveToSaveForLater,
    moveToCart,
    setSaveForLater,
    removeSaveForLaterDetail,
    clearCartDetails,
    clearCartNotifications,
    hideFooterTop,
    showFooterTop,
    submitReturnCart,
    emptyCart
} from '../../actions'

export default (ComposedComponent) => {

    class Cart extends Component {

        /////Currently retrived from backend but if its needed in the front-end this can be used
        // getSubtotal = () => {
        //     const { cartDetail = [] } = this.props
        //     return cartDetail.map((item) => {
        //         return Number(item.price) * Number(item.quantity)
        //     }).reduce((pre, cur) => {
        //         return pre + cur
        //     }, 0).toFixed(2)
        // }


        ////All this info already available in redux but this function
        ////Returns all the subtotal information as a string, with dollar sign and commas and 2 decimal places
        orderSummaryDisplay = () => {
            const { convenienceFee, shipping, subtotal, cartLoading, tax, total } = this.props
            const taxTotal = isNaN(Number(tax)) ? 0 : Number(tax)
            const convenienceFeeTotal = isNaN(Number(convenienceFee)) ? 0 : Number(convenienceFee)
    
            return {
                convenienceFee: convenienceFee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                subtotal: subtotal && subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ,
                shipping: cartLoading ? 'Calculating...' : (shipping && subtotal) ? shipping.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : (subtotal && !shipping) ? 'FREE' : '',
                tax,
                total: cartLoading ? 'Calculating...' : total ? (convenienceFeeTotal + taxTotal + total).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '',
            }
        }

        rxItems = () => this.props.cartDetail.filter((d) => d.rxLevelId)

        render() {
            const props = {
                // getSubtotal: this.getSubtotal,
                orderSummaryDisplay: this.orderSummaryDisplay,
                rxItems: this.rxItems
            }
            return <ComposedComponent {...{ ...this.props, ...props }} />
        }
    }

    const mapStateToProps = ({ cart, auth, punchout }) => {
        return {
            ...cart,
            totalItems: R.pathOr([], ['quantityInput'], cart).map(item => item.quantity).reduce((prev, curr) => prev + curr, 0),
            isPriceVisible: R.pathOr(false, ['user', 'permissions', 'isPriceVisible'], auth),
            isPunchout: punchout.isPunchout
        }
    }

    return withRouter(connect(mapStateToProps,
        {
            calculateTax,
            calculateConvenienceFee,
            resetQuantityInput,
            setCartDetails,
            setSaveForLater,
            editCartDetail,
            removeCartDetail,
            toggleCartPopover,
            editQuantityInput,
            moveToSaveForLater,
            moveToCart,
            removeSaveForLaterDetail,
            clearCartDetails,
            clearCartNotifications,
            showFooterTop,
            hideFooterTop,
            submitReturnCart,
            emptyCart
        })(Cart))
}