import { START_PUNCHOUT, END_PUNCHOUT } from '../actions/types'

const INITAL_STATE = {
  isPunchout: false,
}
// return { type: SET_AUTH_ALERT, payload }
export default (state = INITAL_STATE, { type, payload }) => {
  switch (type) {
    case START_PUNCHOUT:
      return { ...state, isPunchout: true }

    case END_PUNCHOUT:
      return { ...state, isPunchout: false }
    default:
      return state
  }
}
