import ax from 'axios'
import { mergeRight, map, toPairs, join } from 'ramda'
import moment from 'moment'

export const makeQueryStr = (params) => {
  
  const paramStrings = map(
    pair => {
      const [key, val] = pair
      return `${key}=${val}`
    }, 
    toPairs(params)
 )
  return join('&', paramStrings)
}


export const axios = ax.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Authorization': 'Bearer ' + localStorage.getItem('token'),
		'X-Robots-Tag': 'noindex',
		Pragma: 'no-cache'
	}
})

export const refreshTokenMiddleware = (axios) => {
	axios.interceptors.response.use(async (response) => {
		try {
			const { exp, iat } = getTokenDecoded()
			if (!exp) return response

			if (exp > moment().unix() && exp < moment().add(20, 'minutes').unix()) {
				if (localStorage.getItem('tokenRefreshing') !== '1') {
					localStorage.setItem('tokenRefreshing', '1')
					const { data } = await ax.get('/api/auth/refreshToken', {
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
					})

					console.log('refreshed token')
					localStorage.setItem('token', data.token)
					axios.defaults.headers['Authorization'] = `Bearer ${data.token}`
					localStorage.setItem('tokenRefreshing', '0')
				}
			}
		} catch (e) {
			console.log(e)
		}

		return response
	})
}



export const scheduleExpiredTokenCheck = () => {
	try {
		const { exp } = getTokenDecoded()

		const checkExpiration = (time) => {
			setTimeout(() => {
				if (exp < moment().unix()) {
					localStorage.removeItem('token')
					window.location.reload()
				}
				checkExpiration(time)
			}, time) //300000)
		}

		if (exp) checkExpiration(600000)
	} catch (e) {
		console.log(e)
	}
}

export const getTokenDecoded = () => {
	try {
		const token = localStorage.getItem('token')
		var base64Url = token.split('.')[1]
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		return JSON.parse(window.atob(base64))
	} catch (e) {
		return {}
	}
}

// eslint-disable-next-line import/prefer-default-export
export const resolveReq = async (promise, noRedirect) => {
	try {
		const { data } = await promise
		return { data }
	} catch (error) {
		const { data, statusText, status } = error.response
		if (status === 401 && !noRedirect) window.location.href = '/login'
		const message = data.message || data
		return { error: mergeRight(error, { message: message }) }
	}
}

export const getAPIStatus = async () => {
	try {
		await ax.get('/')
		return true
	} catch (error) {
		console.log('API IS DOWN', 'RUNNNN forest RUNNNNNNNNNNNNNNNNNNNNNNNN')
		console.log(error)
		return false
	}
}

export const addChatWidget = () => {
	const script = document.createElement('script')

	script.src = '//code.tidio.co/1xirn8bxnzbgyzcwsydinzhodf3xzxjt.js'
	script.id = 'chatwidget'

	document.body.appendChild(script)
}

export const addInspectlet = () => {
	const inner = `(function () {
                    window.__insp = window.__insp || [];
                    __insp.push(['wid', 1795631574]);
                    var ldinsp = function () {
                        if (typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1795631574&r=' + Math.floor(new Date().getTime() / 3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
                    };
                    setTimeout(ldinsp, 0);
                })();`

	const script = document.createElement('script')

	script.type = 'text/javascript'
	script.id = 'inspectlet'
	script.innerHTML = inner

	document.body.appendChild(script)
}
