
import {
    SET_SALES_ORDER, SET_INVOICE_FILTERS,
    SET_SALES_ORDERS_FILTERS,
    SET_SALES_ORDERS_ADDRESS_LIST, SET_SHIPMENT, SET_INVOICE, SET_REORDER_DATE_OPTION, CLEAR_ALL,
    SET_SELECTED_HISTORY_INVOICE, SET_SELECTED_HISTORY_PAYMENT, SET_PAYMENT_FILTERS, SET_STATUS_LIST, SET_ESTIMATE, SET_ESTIMATE_FILTERS, SET_REORDER_FROM_OPTION, START_LOADER,
    STOP_LOADER
} from './types'
import { resolveReq, axios } from '../utils/api'
import { isArray } from 'lodash'
import { DayPickerSingleDateController } from 'react-dates'

export const setSalesOrder = (payload) => {
    return { type: SET_SALES_ORDER, payload }
}

export const setSalesordersFilters = (payload) => {
    return { type: SET_SALES_ORDERS_FILTERS, payload }
}
export const setEstimate = (payload) => {
    return { type: SET_ESTIMATE, payload }
}

export const setEstimatesFilters = (payload) => {
    return { type: SET_ESTIMATE_FILTERS, payload }
}

export const setInvoiceFilters = (payload) => {
    return { type: SET_INVOICE_FILTERS, payload }
}

export const setInvoice = (payload) => {
    return { type: SET_INVOICE, payload }
}

export const setSelectedHistoryInvoice = (payload) => {
    return { type: SET_SELECTED_HISTORY_INVOICE, payload }
}

export const getSetInvoiceById = (query) => {
    return async dispatch => {
        const { data, error } = await resolveReq(axios.get(`/api/myaccount/transactions/invoices/byId${query}`))
        if (error) // eslint-disable-next-line no-console
            return console.error(error)
        dispatch({ type: SET_SELECTED_HISTORY_INVOICE, payload: data })
    }
}

export const getSetPaymentById = (query) => {
    return async dispatch => {
        const { data, error } = await resolveReq(axios.get(`/api/myaccount/transactions/payments/byId${query}`))
        if (error) // eslint-disable-next-line no-console
            return console.error(error)
        dispatch({ type: SET_SELECTED_HISTORY_PAYMENT, payload: data })
    }
}

export const setSelectedHistoryPayment = (payload) => {
    return { type: SET_SELECTED_HISTORY_PAYMENT, payload }
}

export const setPaymentFilters = (payload) => {
    return { type: SET_PAYMENT_FILTERS, payload }
}

export const setShipment = (payload) => {
    return { type: SET_SHIPMENT, payload }
}

export const setSalesordersAddressList = () => {
    return async dispatch => {
        const { data, error } = await resolveReq(axios.get('/api/myaccount/transactions/getShipToList'))
        if (error) // eslint-disable-next-line no-console
            return console.error(error)

        dispatch({ type: SET_SALES_ORDERS_ADDRESS_LIST, payload: data })
    }
}

export const setStatusList = () => {
    return async dispatch => {
        const { data, error } = await resolveReq(axios.get('/api/myaccount/transactions/getStatusList'))
        if (error) // eslint-disable-next-line no-console
            return console.error(error)

        dispatch({ type: SET_STATUS_LIST, payload: data })
    }
}

export const generateSalesOrderPDF = (type, id) => {
    const arrIds = isArray(id) ? id : [id]
    const payload  = arrIds.map(singleId => ({ type: type, transactionId: singleId })) 

    return async () => {
        const { data, error } = await resolveReq(axios.post('/api/myaccount/transactions/generatePDF', payload))
        
        if (error) // eslint-disable-next-line no-console
            return console.error(error)

        isArray(data)
            ? data.forEach((singlePdf) => window.open('http://' + singlePdf.pdfUrl, '_blank', 'fullscreen=yes'))
            : window.open('http://' + data.pdfUrl, '_blank', 'fullscreen=yes')
    }
}

export const generateStatementPDF = (statementObj) => {
    
    return async () => {
        const { data, error } = await resolveReq(axios.post('/api/myaccount/transactions/generatePDF', { type: 'statement', startDate: statementObj.start, statementDate: statementObj.end, openTransactionsOnly: statementObj.openOnly }))
        if (error) // eslint-disable-next-line no-console
            return console.error(error)

        window.open('http://' + data.pdfUrl, '_blank', 'fullscreen=yes');
    }
}

export const setReorderDateOption = (payload) => {
    return { type: SET_REORDER_DATE_OPTION, payload }
}

export const setReorderFromOption = (payload) => {
    return { type: SET_REORDER_FROM_OPTION, payload }
}

export const clearAll = () => {
    return { type: CLEAR_ALL }
}

export const upsertSubscription = (body) => {
    return async dispatch => {
      dispatch({ type: START_LOADER, payload: 'processingOrderLoading' })
      const { data, error } = await resolveReq(axios.post(`/api/myaccount/transactions/upsertSubscription`, body))
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
      } else {
        const { orderConfirmation, status = '' } = data
        // dispatch({ type: SET_SUBMISSION_DETAILS, payload: { orderConfirmation, status } })
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
        return data
      }
    }
  }

  export const deleteSubscription = (itemListId) => {
    return async dispatch => {
      dispatch({ type: START_LOADER, payload: 'processingOrderLoading' })
      const { data, error } = await axios.post(`/api/myaccount/transactions/deleteSubscription`, {itemListId})
      if (error) {
        console.error(error)
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
      } else {
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
        return data
      }
    }
  }

  export const getSubscriptionAddressPaymentDetails = (itemListId) => {
    return async dispatch => {
      dispatch({ type: START_LOADER, payload: 'processingOrderLoading' })
      const { data, error } = await axios.post(`/api/myaccount/transactions/getSubscriptionAddressPaymentDetails`, {itemListId})
      if (error) {
        console.error(error)
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
      } else {
        dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
        return data
      }
    }
  }
