import React, { Component } from 'react'
import { connect } from 'react-redux'
import EKGLoader from '../EKGLoader/EKGLoader'
import ProcessingLoader from '../ProcessingLoader/ProcessingLoader'

// eslint-disable-next-line react/prefer-stateless-function
class OverlayLoaders extends Component {

    shouldComponentUpdate(prev, next) {
        return next !== this.props
    }

    render() {
        const orderTexts = [{ text: 'Dispatching to warehouse', delay: 3000 }, { text: 'Rome wasn\'t built in a day you know!', delay: 8000 }]
        const paymentTexts = [{ text: 'Verifying Payment Details', delay: 3000 }, { text: 'Rome wasn\'t built in a day you know!', delay: 8000 }]
        const { processingOrderLoading, ekgLoading, processingPaymentLoading } = this.props
        return (
            <>
                {processingPaymentLoading && <ProcessingLoader texts={paymentTexts} mainText='Processing Payment' />}
                {processingOrderLoading && <ProcessingLoader texts={orderTexts} mainText='Processing Order' />}
                {ekgLoading && <EKGLoader />}
            </>
        )
    }
}


const mapStateToProps = ({ loader: { processingOrderLoading, ekgLoading, processingPaymentLoading } }) => {
    return { processingOrderLoading, ekgLoading, processingPaymentLoading }
}

export default connect(mapStateToProps)(OverlayLoaders)