import qs from 'qs'
import { isEmpty, uniq, map, prop, compose, filter, keys, toString, pathOr, not } from 'ramda'

const { PUBLIC_URL } = process.env

export const renderAvailablility = (quantity, isAllocationDisplay = false) => {
    const num = Number(quantity)

    if (isAllocationDisplay)
        return { text: `Availability: ${num}`, type: num > 20 ? 'success' : 'danger' }

    else {
        if (num > 0)
            return { text: 'In stock', type: 'success' }
        return { text: 'Out of Stock', type: 'danger' }
    }
}

export const addDefaultSrc = e => e.target.src = `${PUBLIC_URL}/Images/imageComingSoon.png`

export const getUrlComponent = (item) => {
    if (isNaN(item.urlComponent) && item.urlComponent)
        return item.urlComponent

    return item.id
}

export const flyToCart = () => {
    try {
        if (window.innerWidth < 1000)
            return

        const viewcart = document.getElementsByClassName('cart-drag')[0]

        const imgtodrag = document.getElementsByClassName('product-details-image-col')[0]
        const imgtodragImage = document.querySelector('.product-img-drag')

        const disLeft = imgtodrag.getBoundingClientRect().left
        const disTop = imgtodrag.getBoundingClientRect().top
        const cartleft = viewcart.getBoundingClientRect().left
        const carttop = viewcart.getBoundingClientRect().top
        const image = imgtodragImage.cloneNode(true)

        image.style = `z-index: 1111; width: 150px;opacity:0.8; position:fixed; top: ${disTop}px; left: ${disLeft}px; transition: left 2s, top 3s, height 1s, width 1s, opacity 2.2s cubic-bezier(1, 1, 1, 1)`
        const rechange = document.body.appendChild(image)
        setTimeout(function () {
            image.style.left = `${cartleft}px`
            image.style.top = `${carttop}px`
            image.style.width = '50px'
            image.style.opacity = '0'
        }, 200)
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange)
        }, 2200)
    }
    catch (e) {
        console.log('Fly to cart error', e.message)
    }

}


export const queryStringWithRedirect = (path = '/', queryString) => {
    const queryObj = qs.parse(queryString, { ignoreQueryPrefix: true })
    return qs.stringify({ ...queryObj, redirect: path }, { addQueryPrefix: true })
}

export const getCartRxLevels = ({ cartDetail = [] }) => {
    return compose(
        map(toString),
        filter(a => a),
        uniq,
        map(prop('rxLevelId'))
    )(cartDetail)
}
export const getCartRxLevelsWithoutPhysicianOnly = ({ cartDetail = [] }) => getCartRxLevels({ cartDetail }).filter(rxLevel => rxLevel !== 1)

export const validAddressOptions = ({ addresses, cartDetail }) => {

    const cartRxLevels = getCartRxLevelsWithoutPhysicianOnly({ cartDetail })

    if (isEmpty(cartRxLevels))
        return addresses

    const validRxAddresses = addresses.filter(a => {
        const { controlledSubstance = {} } = a

        ////Probably not needed since backend should only send back nonexpired licenses, but just in case
        const nonExpiredLicenses = filter(cs => {
            const { expiration } = cs
            return new Date(expiration).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
        }, controlledSubstance)

        const addressRxLevels = keys(nonExpiredLicenses)
        return cartRxLevels.every(cartRxLevel => addressRxLevels.includes(cartRxLevel))
    })

    return validRxAddresses
}


export const addressHasControlledSubtance = ({ addressObj = {} }) => {
    
    if (isEmpty(addressObj))
        return false

    return compose(
        not,
        isEmpty,
        filter(a => a.id !== 1), ////level 1, phyisican only can change address info
        pathOr({}, ['controlledSubstance'])
    )(addressObj)
}