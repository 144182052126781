import React, { useState, useEffect, useRef } from 'react';
import ProductDetailsAlternativeProduct from './ProductDetailsAlternativeProduct';
import { debounce } from 'lodash';
import 'tiny-slider/dist/tiny-slider.css';

const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG } = process.env;

const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG;

const ProductDetailsAlternativeProductsSlider = ({ productDetailsProps, sliderInstanceId }) => {
	const [alternativeProducts, setAlternativeProducts] = useState([]);

	const currentInstanceId = useRef(sliderInstanceId).current;
	const sliderRef = useRef(null);

	const tnsPromise = import('tiny-slider/src/tiny-slider').then((module) => module.tns);
	const alternateProductListClass = 'product-details__alternative-products-list--alternate';
	const reachedContainerWidthClass = 'product-details__alternative-products-list--max';

	const handleResizeDebounced = () => {
		initializeSlider();
	};

	const executeResizeDebounced = debounce(handleResizeDebounced, 200);

	const initializeSlider = () => {
		if (sliderRef.current) {
			sliderRef.current.destroy();
		}

		(async () => {
			const currentSliderDiv = document.getElementById(currentInstanceId);
			const tns = await tnsPromise;

			const getTinySliderInstance = (props) => {
				const options = {
					container: `#${currentInstanceId}`,
					items: props.itemsToShow,
					slideBy: 'page',
					controls: true,
					controlsContainer: '.product-details__alternative-products-controls',
					navPosition: 'bottom',
					mouseDrag: true,
					gutter: props.gutter,
					edgePadding: props.edgePadding,
					lazyload: true,
					loop: false,
				};

				return tns(options);
			};

			if (currentSliderDiv) {
				const trackElement = currentSliderDiv.parentElement;
				const parentDivElement = currentSliderDiv.closest('.product-details__alternative-products-list');

				if (trackElement && parentDivElement) {
					const containerWidth = trackElement.offsetWidth;

					if (containerWidth < 650) {
						parentDivElement.classList.remove(alternateProductListClass);
						parentDivElement.classList.remove(reachedContainerWidthClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 1, gutter: 16, edgePadding: 16 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 1) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 16px';
						}
					} else if (containerWidth < 768) {
						parentDivElement.classList.remove(alternateProductListClass);
						parentDivElement.classList.remove(reachedContainerWidthClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 2, gutter: 16, edgePadding: 16 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 2) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 16px';
						}
					} else if (containerWidth < 992) {
						parentDivElement.classList.remove(reachedContainerWidthClass);
						parentDivElement.classList.add(alternateProductListClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 2, gutter: 16, edgePadding: 16 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 2) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 16px';
						}
					} else if (containerWidth < 1400) {
						parentDivElement.classList.remove(reachedContainerWidthClass);
						parentDivElement.classList.add(alternateProductListClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 2, gutter: 48, edgePadding: 48 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 2) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 48px';
						}
					} else if (containerWidth < 1440) {
						parentDivElement.classList.remove(reachedContainerWidthClass);
						parentDivElement.classList.add(alternateProductListClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 3, gutter: 48, edgePadding: 48 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 3) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 48px';
						}
					} else if (containerWidth < 1600) {
						parentDivElement.classList.remove(reachedContainerWidthClass);
						parentDivElement.classList.add(alternateProductListClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 3, gutter: 20, edgePadding: 20 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 3) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 20px';
						}
					} else {
						parentDivElement.classList.add(reachedContainerWidthClass);
						parentDivElement.classList.add(alternateProductListClass);

						sliderRef.current = getTinySliderInstance({ itemsToShow: 3, gutter: 20, edgePadding: 20 });
						const sliderRefInfo = sliderRef.current.getInfo();

						if (sliderRefInfo.slideCount <= 3) {
							sliderRefInfo.container.parentElement.style = 'margin: 0 0 0 20px';
						}
					}
				}
			}

			return () => {
				if (sliderRef.current) {
					sliderRef.current.destroy();
				}
			};
		})();
	};

	useEffect(() => {
		const fetchAlternativeProduct = () => {
			setAlternativeProducts(productDetailsProps.alternativeItems);
		};

		fetchAlternativeProduct();
		initializeSlider();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			executeResizeDebounced();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [executeResizeDebounced]);

	return (
		<React.Suspense fallback={<p>Loading...</p>}>
			<div className="product-details__alternative-products-list">
				<div className="product-details__alternative-products-wrapper">
					<div id={currentInstanceId} className="product-details__alternative-products-track">
						{alternativeProducts.map((product, i) => {
							const productImageSrc = product.thumbnailImage;
							const productPath = product.urlComponent;
							const productManufacturer = product.manufacturer;
							const productName = product.siteDisplayName || product.displayName;
							const productPrice = product.price;

							return (
								<ProductDetailsAlternativeProduct
									key={i}
									path={productPath}
									productImageSrc={productImageSrc}
									productManufacturer={productManufacturer}
									productName={productName}
									productPrice={productPrice}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</React.Suspense>
	);
};

export default ProductDetailsAlternativeProductsSlider;
