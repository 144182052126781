
import { pathOr } from 'ramda'
import { resolveReq, axios } from '../utils/index'
import { START_LOADER, STOP_LOADER, SET_CREDIT_CARD_PROP, UPDATE_CREDIT_CARD } from './types'

// eslint-disable-next-line no-console
const logIfError = (e) => e && console.log(e.message)

export const getCreditCards = () => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'creditCardsLoading' })
        const { data, error } = await resolveReq(axios.get('/api/myaccount/paymentmethod/getAll'))
        logIfError(error)

        if (data)
            dispatch({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'cards', data } })
        dispatch({ type: STOP_LOADER, payload: 'creditCardsLoading' })
    }
}

export const upsertCreditCard = (card) => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'creditCardUpdating' })
        const { error, data } = await resolveReq(axios.post('/api/myaccount/paymentmethod/upsert', card))
        logIfError(error)

        if (data) {
            dispatch({ type: UPDATE_CREDIT_CARD, payload: data })
            dispatch({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'modal', data: false } })
        }

        dispatch({ type: STOP_LOADER, payload: 'creditCardUpdating' })

        if (error) {
            const errorMessage = pathOr('Error Uploading Payment Method', ['response', 'data', 'message'], error)
            console.log("TCL: upsertCreditCard -> errorMessage", errorMessage)
            return { error: typeof(errorMessage) === 'string'? errorMessage: 'Error Uploading Payment Method' }
        }

    }
}

export const toggleCreditCardModal = (open) =>
    ({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'modal', data: Boolean(open) } })

export const setCreditCardProp = (prop, data) =>
    ({ type: SET_CREDIT_CARD_PROP, payload: { prop, data } })

export const setCreditCard = (data) =>
    ({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'card', data } })

export const deleteCreditCard = (id) => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'creditCardUpdating' })
        const { error, data } = await resolveReq(axios.post('/api/myaccount/paymentmethod/delete', { id }))
        logIfError(error)

        if (data)
            dispatch({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'modal', data: false } })

        dispatch({ type: STOP_LOADER, payload: 'creditCardUpdating' })
    }
}

export const getCreditCardTypes = (card) => {
    return async dispatch => {
        const { error, data } = await resolveReq(axios.get('/api/myaccount/paymentmethod/getTypes', card))
        logIfError(error)

        if (data)
            dispatch({ type: SET_CREDIT_CARD_PROP, payload: { prop: 'creditCardType', data } })
    }
}