import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { pathOr } from 'ramda'
import './Navbar.scss'
import { Row, Col, Container } from 'reactstrap'


class NavDropDown extends Component {
    state = { show: false }
    render() {
        const categories = pathOr([], ['categories'], this.props)

        let showList = () => {
            this.setState({ show: true })
        }

        let hideList = () => {
            this.setState({ show: false })
        }

        ////Prevent the click on white space padding of the dropdown
        const ignoreClick = (e) => {
            e.preventDefault()
        }

        let dropDownItems = categories.map((item, index) => {
            /* Now navigating to /category/category_name */
            const { urlComponent, id, name } = item
            const urlLink = urlComponent || id
            return (
                <div className='drop-down-item col-6' onClick={() => hideList()} key={index}>
                    <Link to={`/category/${urlLink}`} onClick={() => this.props.selectCategory(item)} className='link'>
                        <div className="d-flex justify-content-between drop-down-link" style={{ padding: '20px' }}><span>{name}</span><i className="mdi mdi-chevron-right" /></div>
                    </Link>
                </div>
            )
        })

        return (
            <div className="border-left flex-fill tab" onMouseEnter={() => showList()} onMouseLeave={() => hideList()}>
                <Link className="link" to='/shop'>
                    <span onClick={() => hideList()}>{this.props.navItemName} <i className="mdi mdi-chevron-down" /></span>
                </Link>
                <div style={{ paddingLeft: 0, width: '50%' }} className={`header-drop-down ${this.state.show ? 'show' : ''}`} id="header-drop-down-box" onClick={ignoreClick}>
                    <div className="row">
                        {dropDownItems}
                    </div>
                </div>
            </div>
        )
    }
}

export default NavDropDown