import React, { useState, useEffect, useRef, Fragment } from 'react';
import classnames from 'classnames';
import LeftChevronSVG from '../../shared/icons/LeftChevronSVG';
import RightChevronSVG from '../../shared/icons/RightChevronSVG';
import { Statsig } from 'statsig-react';

const mql = window.matchMedia('(min-width: 62em)');

const MainNavigationMenu = ({ 
	menuId, categoryPath, isExpanded, isCurrentlyActive, text, depth, 
	parentMenu, activeMenus, dispatch, children, currentlyActiveMenu,
	opensOnHover, onCategoryClick
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const mainNavigationMenuRef = useRef(null);
	let mainNavigationMenuClasses;

	if (depth === 0) {
		mainNavigationMenuClasses = classnames('dealmed-main-navigation__menu', {
			'dealmed-main-navigation__menu--expanded': isExpanded,
			'dealmed-main-navigation__menu--visible': isVisible,
			'dealmed-main-navigation__menu--overflow': isCurrentlyActive,
		});
	} else {
		mainNavigationMenuClasses = classnames('dealmed-main-navigation__submenu', {
			'dealmed-main-navigation__submenu--expanded': isExpanded,
			'dealmed-main-navigation__submenu--visible': isVisible,
			'dealmed-main-navigation__submenu--overflow': isCurrentlyActive,
		});
	}

	const handleBlur = (e) => {
		
		if (e.relatedTarget === null) {
			return;
		}

		if (!e.currentTarget.contains(e.relatedTarget)) {
			dispatch({ type: 'UPDATE_ACTIVE_MENUS', payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current } });
		}
	};

	const handleTransitionEnd = (e) => {
		if (e.currentTarget === e.target && e.propertyName === 'transform') {
			if (!isExpanded) {
				setIsVisible(false);
			}
		}
	};

	const logStatsigEvent = (linkTitle) => {
		Statsig.logEvent("Nav_Button_Click", linkTitle)
	  }

	useEffect(() => {
		if (mql.matches) {
			setIsVisible(isExpanded);
			return;
		}

		if (isExpanded) {
			setIsVisible(true);
		}
	}, [isExpanded]);

	return (
		<>
			<button
				type="button"
				className={`dealmed-main-navigation__btn`}
			
				onMouseEnter={() => {
					if (opensOnHover && !isCurrentlyActive){

						dispatch({ type: 'UPDATE_ACTIVE_MENUS', payload: { menuId, depth, menuElement: mainNavigationMenuRef.current } })
						for (const menu of activeMenus) {
							menu.menuElement.scrollTo(0, 0);
						}
						// logStatsigEvent(text)
					}
				
				}}
		
				onClick={() => {

					/* 
						If the menu opensOnHover, then an onClick event navigates you 
						to a category page.  We use the onCategoryClick (which should exist)
						passed from the parent.
					*/
					if (opensOnHover && onCategoryClick){
						onCategoryClick(categoryPath || menuId)
						logStatsigEvent(text)
					}

					else {

						dispatch({ 
							type: 'UPDATE_ACTIVE_MENUS', 
							payload: { 
								menuId: menuId, depth: depth, 
								menuElement: mainNavigationMenuRef.current 
							} 
						})

						for (const menu of activeMenus) {
							menu.menuElement.scrollTo(0, 0);
						}

						logStatsigEvent(text)
					}
					

				}}
			>

				<span className={`dealmed-main-navigation__btn-text`}>{text}</span>
				<span className="dealmed-main-navigation__icon-wrapper">
					<RightChevronSVG className="dealmed-main-navigation__btn-icon" />
				</span>
			</button>
			<div ref={mainNavigationMenuRef} className={mainNavigationMenuClasses} onBlur={handleBlur} onTransitionEnd={handleTransitionEnd}>
				<ul className="dealmed-main-navigation__list">
					<li className="dealmed-main-navigation__item">
						<button
							type="button"
							className="dealmed-main-navigation__back-btn"
							onClick={() =>
								dispatch({ type: 'UPDATE_ACTIVE_MENUS', payload: { menuId: menuId, depth: depth, menuElement: mainNavigationMenuRef.current } })
							}
						>
							<span className="dealmed-main-navigation__icon-wrapper" >
								<LeftChevronSVG className="dealmed-main-navigation__back-btn-icon" />
							</span>
							<span className="dealmed-main-navigation__back-btn-text" >Back to {parentMenu}</span>
						</button>
					</li>
					{children}
				</ul>
			</div>
		</>
	);
};

export default MainNavigationMenu;