import { SET_LIST } from '../actions/types'

const INITIAL_STATE = {
    permissions: []
}

export default (state = INITIAL_STATE, { type, payload }) => {

    switch (type) {

        case SET_LIST:
            return { ...state, [payload.listName]: payload.data }

        default:
            return state
    }
}