import React from 'react'
import { mergeRight } from 'ramda'
import { ToastContainer, toast } from 'react-toastify'

const notify = ({ message, type = 'default', custom, customGreen, customGray, position = toast.POSITION.BOTTOM_RIGHT }) => {
    if (type == 'default')
        return toast(message, { position })

    toast[type](message, mergeRight({ position }, custom ? {
        className: 'toastify-red-bar',
        bodyClassName: 'toastify-body',
        progressClassName: 'toastify-progress'
    } : customGreen? {
        className: 'toastify-green-bar',
        bodyClassName: 'toastify-body',
        progressClassName: 'toastify-progress'
    }: customGray? {
        className: 'toastify-gray-bar',
        bodyClassName: 'toastify-body',
        progressClassName: 'toastify-progress'
    }:{}))
}

export default (ComposedComponent) => {
    const ToastifyComposed = (props) => {
        return (
            <>
                <ToastContainer />
                <ComposedComponent {...{ ...props, toastify: notify }} />
            </>
        )
    }
    return ToastifyComposed
}
