import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as R from 'ramda'
import { Row, Col, Popover, PopoverBody } from 'reactstrap'
import './Navbar.scss'
import NavCartPopoverCard from './NavCartPopoverCard'


const NavCartPopover = (props) => {

    const renderPopoverBody = () => {
        const isNullOrEmpty = R.either(R.isNil, R.isEmpty)
        const { cartLoading, totalItems, cartDetail, toggleCartPopover, isPriceVisible, orderSummaryDisplay } = props

        if (cartLoading || (isNullOrEmpty(cartDetail)))
            return (
                <Fragment>
                    <PopoverBody>
                        <Row>
                            <Col>
                                {cartLoading ? 'Your cart is loading...' : 'Your cart is empty'}
                            </Col>
                        </Row>
                    </PopoverBody>
                </Fragment>
            )

        ////else if cart has contents, display them
        return (
            <Fragment>
                <PopoverBody style={{ maxHeight: '600px', overflowY:'scroll' }}>
                    {cartDetail.map((item, index) => <NavCartPopoverCard key={index} item={item} isPriceVisible={isPriceVisible} />)}
                    {/* CART SUMMARY */}
                    <div className='nav-popover-bottom-section'>
                        <Row className='nav-popover-totals-row'>
                            <Col xs='5' className='nav-popover-product-total'>
                                {`${totalItems} Products`}
                            </Col>
                            <Col xs='7'>
                                {'Subtotal: '}
                                <span className='nav-popover-price'>
                                    {R.pathOr('', ['subtotal'], orderSummaryDisplay)}
                                </span>
                            </Col>
                        </Row>
                        <Row className='nav-popover-buttons-row'>
                            <Col>
                                <Link to='/cart'>
                                    <button onClick={toggleCartPopover} className='btn btn-block btn-outline-primary' type='button'>View Cart</button>
                                </Link>
                            </Col>
                            <Col>
                                <Link to='/checkout'>
                                    <button onClick={toggleCartPopover} className='btn btn-block btn-primary' type='button'>Checkout</button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </PopoverBody>
            </Fragment>
        )
    }

    return (
        <Popover
            className="d-none d-lg-block nav-popover"
            target={props.targetId}
            isOpen={props.isOpen}
            toggle={props.toggleCartPopover}
            trigger="hover"
            placement="bottom"
            delay={150}
        >
            {renderPopoverBody()}
        </Popover>
    )
}

export default NavCartPopover
