import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { filter, keys, compose, pathOr } from "ramda";
import {
  toggleMenu,
  toggleHamburgerMenu,
  setActiveMenuItem,
} from "../../actions";

// eslint-disable-next-line import/no-anonymous-default-export
export default (ComposedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class MyAccountHOC extends Component {
    hasPermission = (permission) => this.props.permissions.includes(permission);

    render() {
      return (
        <ComposedComponent
          {...{ ...this.props, hasPermission: this.hasPermission }}
        />
      );
    }
  }

  const mapStateToProps = ({
    myAccount,
    auth /*: { user: { permissions } }*/,
  }) => {
    //console.log(permissions)
    return {
      permissions: compose(
        keys,
        filter((p) => p)
      )(pathOr({}, ["user", "permissions"], auth)),
      auth: auth,
      menu: myAccount.menu,
      activeMenu: myAccount.activeMenu,
      activeSubMenu: myAccount.activeSubMenu,
      hamburgerToggle: myAccount.hamburgerToggle,
      contact: myAccount.contact,
    };
  };

  return connect(mapStateToProps, {
    toggleMenu,
    toggleHamburgerMenu,
    setActiveMenuItem,
  })(withRouter(MyAccountHOC));
};
