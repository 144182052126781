import React from 'react';
import classnames from 'classnames';

const RightChevronSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 32 32" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M22.163 15.433c0.316 0.313 0.316 0.82 0 1.133l-9.856 9.865c-0.316 0.313-0.826 0.313-1.141 0l-1.329-1.32c-0.316-0.313-0.316-0.82 0-1.133l7.956-7.979-7.956-7.979c-0.316-0.313-0.316-0.82 0-1.133l1.329-1.32c0.316-0.313 0.826-0.313 1.141 0l9.856 9.865z"></path>
		</svg>
	);
};

export default RightChevronSVG;
