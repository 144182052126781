import aa from 'search-insights'

export const initializeAlgoliaInsights = () => {
    aa('init', {
      appId: '9487DJSWRC',
      apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
      // useCookie: true, // need to get user consent
    })
}

