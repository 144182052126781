import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import MyAccountHOC from '../Reusable/MyAccountHOC';
import InitializeHOC from '../Reusable/InitializeHOC';
import RelatedAccountsDropdown from '../Reusable/RelatedAccountsDropdown';
import { logout, switchAccount } from '../../actions';
import DealmedLogoSVG from '../../shared/icons/DealmedLogoSVG';
import UserCircleSVG from '../../shared/icons/UserCircleSVG';
import CloseSVG from '../../shared/icons/CloseSVG';

const MainNavigationHeader = ({ auth, isAuthenticated, logout, cleanupOnLogout, retrievingRelatedAccounts = false, dispatch }) => {
	const hasRelatedAccounts = pathOr([], ['relatedAccounts'], auth).length > 1;

	const handleLogout = () => {
		logout();
		cleanupOnLogout();
		dispatch({ type: 'CLOSE_MAIN_NAVIGATION' });
	};

	return (
		<div className="dealmed-main-navigation__header">
			<div className="dealmed-main-navigation__logo">
				<Link to="/" className="dealmed-main-navigation__logo-link" onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}>
					<span className="visually-hidden">Homepage</span>
					<DealmedLogoSVG className="dealmed-main-navigation__logo-icon" />
				</Link>
			</div>
			<div className="dealmed-main-navigation__account">
				<div className="dealmed-main-navigation__account-top">
					<span className="dealmed-main-navigation__icon-wrapper">
						<UserCircleSVG className="dealmed-main-navigation__account-icon" />
					</span>
					{isAuthenticated && auth.user ? <span className="dealmed-main-navigation__account-name">Hello, {auth.user.firstName}!</span> : null}
				</div>
				<div className="dealmed-main-navigation__account-bottom">
					{isAuthenticated ? (
						<>
							<Link to="/myaccount" className="dealmed-main-navigation__account-link" onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}>
								Dealmed Runtime&#8482; Account
							</Link>
							<button type="button" className="dealmed-main-navigation__account-link dealmed-main-navigation__account-logout" onClick={handleLogout}>
								Logout
							</button>
						</>
					) : (
						<>
							<Link to="/myaccount" className="dealmed-main-navigation__account-link" onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}>
								Login
							</Link>
							<Link to="/register" className="dealmed-main-navigation__account-link" onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}>
								Register
							</Link>
						</>
					)}
				</div>
				{isAuthenticated ? (
					hasRelatedAccounts ? (
						<div className="dealmed-main-navigation__account-related">
							{retrievingRelatedAccounts ? <p>Loading related accounts...</p> : <RelatedAccountsDropdown isSearchable />}
						</div>
					) : null
				) : null}
			</div>
			<button type="button" className="dealmed-main-navigation__close-btn" onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}>
				<span className="visually-hidden">Close Main Navigation</span>
				<CloseSVG className="dealmed-main-navigation__close-btn-icon" />
			</button>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		isAuthenticated: pathOr(false, ['user', 'id'], state.auth),
		retrievingRelatedAccounts: pathOr(false, ['loader', 'retrievingRelatedAccounts'], state),
	};
};

export default connect(mapStateToProps, { logout, switchAccount })(MyAccountHOC(InitializeHOC(MainNavigationHeader)));
