import React, { useState, useEffect } from 'react'
import { FadeLoader } from 'react-spinners'
import './ProcessingLoader.scss'
import styles from '../../../assets/CSS/_global_variables.scss'
//const texts = [{ text: 'Dispatching to warehouse', delay: 3000 }, { text: 'Rome wasn\'t built in a day you know!', delay: 8000 }]

export default (props) => {
    const [text, setText] = useState('')

    useEffect(() => {
        props.texts.map(({ text, delay }) => {
            setTimeout(() => {
                setText(text)
            }, delay)
        })
        return () => setText('')
    }, [])

    return (
        <div className='loader-wrapper'>
            <div className="loader ml-3">
                <FadeLoader
                    radius={2}
                    margin="2px"
                    width={3}
                    height={20}
                    color={styles.colorPrimary}
                    loading
                />
            </div>
            <h4 className="text-center mt-5">{props.mainText}</h4>
            <h5 className="text-center text-muted mt-4">{text}</h5>
        </div>
    )
}


