/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { setProductWithLogin, resetProduct } from '../../actions'

export default (ComposedComponent) => {

    class ProductHOC extends Component {

        setProduct = (id, startLoader = false, clickEventObject = {}) => {
          
            return  this.props.setProductWithLogin(id, startLoader, this.props.isAuthenticated, clickEventObject )
        }
        render() {
            const props = {
                setProduct: this.setProduct,
            }
            return <ComposedComponent {...{ ...this.props, ...props }} />
        }
    }

    const mapStateToProps = state => ({ isAuthenticated: R.pathOr(false, ['auth', 'user', 'id'], state) })
    return connect(mapStateToProps, { setProductWithLogin, resetProduct })(ProductHOC)
}
