import React from 'react';
import classnames from 'classnames';

const DealmedSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 209 44" className={classnames('dealmed-icon', className)} role="img" aria-labelledby="dealmed-logo-icon">
			<title id="dealmed-logo-icon">Dealmed</title>
			<defs>
				<linearGradient x1="631.09045%" y1="629.068947%" x2="632.374096%" y2="629.068947%" id="dealmed-svg-linear-gradient">
					<stop stopColor="#8F1E39" offset="0%"></stop>
					<stop stopColor="#8F1E39" offset="0.0455097004%"></stop>
					<stop stopColor="#BB0C33" offset="100%"></stop>
				</linearGradient>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(0.000000, 0.000000)">
					<path
						d="M75.2286824,34.0733232 L69.5547932,34.0733232 L69.5547932,32.9308062 C68.0446919,33.9106646 66.2076252,34.4819536 64.0035542,34.4819536 C58.6155842,34.4819536 54.248,30.0327349 54.248,24.2772417 C54.248,18.6033525 58.6155842,14.1130571 64.0035542,14.1130571 C66.2076252,14.1130571 68.0446919,14.6848955 69.5547932,15.6642351 L69.5547932,5.092 L75.2286824,5.092 L75.2286824,34.0733232 Z M69.5547932,27.420125 L69.5547932,21.1749163 C68.2895344,19.5010576 66.6156758,18.9297686 64.9423665,18.9297686 C61.9627217,18.9297686 59.8808125,21.4197588 59.8808125,24.2772417 C59.8808125,27.1752825 61.9627217,29.7057695 64.9423665,29.7057695 C66.6156758,29.7057695 68.2895344,29.1344805 69.5547932,27.420125"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<path
						d="M97.3952661,26.3579951 L83.1494165,26.3579951 C83.7207056,28.3172236 85.2313562,29.8278438 88.0477625,29.8278438 C89.6400171,29.8278438 90.7420068,29.3381892 91.3538232,28.3583003 L97.2731348,28.3583003 C96.2116113,32.1135188 92.5380273,34.5218745 87.9250818,34.5218745 C81.6393457,34.5218745 77.435,30.1543208 77.435,24.3177119 C77.435,18.4395381 81.598269,14.113 87.8434473,14.113 C93.3541284,14.113 97.5173975,18.0725337 97.5173975,23.9091426 C97.5173975,24.725793 97.5173975,25.541894 97.3952661,26.3579951 M83.0678125,22.3984919 L91.884585,22.3984919 C91.5581689,20.1133662 90.0480371,18.7665034 87.6802393,18.7665034 C85.0681177,18.7665034 83.5985742,20.3171016 83.0678125,22.3984919"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<path
						d="M119.805652,34.0732661 L114.132312,34.0732661 L114.132312,32.930688 C112.621692,33.9105769 110.785144,34.481866 108.580554,34.481866 C103.192584,34.481866 98.825,29.9916011 98.825,24.3177119 C98.825,18.5621882 103.192584,14.113 108.580554,14.113 C110.785144,14.113 112.621692,14.6848384 114.132312,15.6641475 L114.132312,14.5215999 L119.805652,14.5215999 L119.805652,34.0732661 Z M114.132312,27.4200068 L114.132312,21.1748286 C112.866504,19.4604426 111.193225,18.8891841 109.519366,18.8891841 C106.539722,18.8891841 104.457812,21.4197017 104.457812,24.3177119 C104.457812,27.1751948 106.539722,29.6651545 109.519366,29.6651545 C111.193225,29.6651545 112.866504,29.0938655 114.132312,27.4200068"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<rect id="Rectangle-1" fill="#3D4647" fillRule="nonzero" x="123.15448" y="5.0916748" width="5.6733551" height="28.9813118"></rect>
					<path
						d="M164.545568,34.0735813 L158.871663,34.0735813 L158.871663,23.8277622 C158.871663,20.7254673 157.361577,19.052158 154.708394,19.052158 C153.524206,19.052158 152.217885,19.5828892 151.034246,21.2156711 C151.11585,21.9096409 151.198003,22.6030613 151.198003,23.3381077 L151.198003,34.0735813 L145.523565,34.0735813 L145.523565,23.8277622 C145.523565,20.7254673 144.014028,19.052158 141.319768,19.052158 C140.217733,19.052158 138.993017,19.705051 137.849904,21.2561985 L137.849904,34.0735813 L132.176,34.0735813 L132.176,14.5213352 L137.849904,14.5213352 L137.849904,15.70499 C139.074636,14.5618625 140.380956,13.909 142.666616,13.909 C145.156576,13.909 147.360631,14.8883396 148.911809,16.6848789 C150.993719,14.7662083 152.952932,13.909 156.014715,13.909 C160.790349,13.909 164.545568,17.6231418 164.545568,23.3381077 L164.545568,34.0735813 Z"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<path
						d="M186.589266,26.3579951 L172.343447,26.3579951 C172.914721,28.3172236 174.425356,29.8278438 177.241762,29.8278438 C178.833453,29.8278438 179.936037,29.3381892 180.547839,28.3583003 L186.46712,28.3583003 C185.405627,32.1135188 181.732027,34.5218745 177.119067,34.5218745 C170.833346,34.5218745 166.629,30.1543208 166.629,24.3177119 C166.629,18.4395381 170.792269,14.113 177.037997,14.113 C182.548128,14.113 186.711947,18.0725337 186.711947,23.9091426 C186.711947,24.725793 186.711947,25.541894 186.589266,26.3579951 M172.261828,22.3984919 L181.078585,22.3984919 C180.752138,20.1133662 179.241518,18.7665034 176.874239,18.7665034 C174.261568,18.7665034 172.792574,20.3171016 172.261828,22.3984919"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<path
						d="M209.000118,34.0733232 L203.326763,34.0733232 L203.326763,32.9308062 C201.816677,33.9106646 199.97961,34.4819536 197.775554,34.4819536 C192.38702,34.4819536 188.02,30.0327349 188.02,24.2772417 C188.02,18.6033525 192.38702,14.1130571 197.775554,14.1130571 C199.97961,14.1130571 201.816677,14.6848955 203.326763,15.6642351 L203.326763,5.092 L209.000118,5.092 L209.000118,34.0733232 Z M203.326763,27.420125 L203.326763,21.1749163 C202.06097,19.5010576 200.38821,18.9297686 198.714366,18.9297686 C195.734722,18.9297686 193.652813,21.4197588 193.652813,24.2772417 C193.652813,27.1752825 195.734722,29.7057695 198.714366,29.7057695 C200.38821,29.7057695 202.06097,29.1344805 203.326763,27.420125"
						fill="#3D4647"
						fillRule="nonzero"
					></path>
					<g>
						<mask id="dealmed-svg-mask" fill="white">
							<path d="M9.15524292,28.6871948 C9.15524292,25.7672424 11.5312805,23.3912048 14.4512024,23.3912048 L19.7477112,23.3912048 L19.7477112,28.6871948 C19.7477112,31.607666 17.3717041,33.9842529 14.4512024,33.9842529 C11.5312805,33.9842529 9.15524292,31.607666 9.15524292,28.6871948 M19.7477112,4.57733154 L19.7477112,14.2365112 L14.4512024,14.2365112 C6.48287964,14.2365112 0,20.7193909 0,28.6871948 C0,36.6560669 6.48287964,43.1389465 14.4512024,43.1389465 C22.4200745,43.1389465 28.9029846,36.6560669 28.9029846,28.6871948 L28.9029846,23.3912048 L38.0916443,23.3912048 C40.6194153,23.3912048 42.6690063,21.3421631 42.6690063,18.8138428 C42.6690063,16.2855225 40.6194153,14.2365112 38.0916443,14.2365112 L28.9029846,14.2365112 L28.9029846,4.57733154 C28.9029846,2.04956055 26.8533936,0 24.3251038,0 C21.7967834,0 19.7477112,2.04956055 19.7477112,4.57733154"></path>
						</mask>
						<rect
							fill="url(#dealmed-svg-linear-gradient)"
							fillRule="nonzero"
							mask="url(#dealmed-svg-mask)"
							x="-0.000234014122"
							y="0"
							width="42.6690102"
							height="43.1389656"
						></rect>
					</g>
					<path d="M19.748,23.392 L28.9032734,23.392 L19.748,26.0638445 L19.748,23.392 Z" fill="#1A1818" fillRule="nonzero"></path>
				</g>
			</g>
		</svg>
	);
};

export default DealmedSVG;
