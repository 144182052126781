import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Navbar.scss'


class ResourcesDropdown extends Component {
    state = { show: false }
    render() {

        let showList = () => {
            this.setState({ show: true })
        }

        let hideList = () => {
            this.setState({ show: false })
        }

        ////Prevent the click on white space padding of the dropdown
        const ignoreClick = (e) => {
            e.preventDefault()
        }

        return (
            <div className="main-nav-dropdown" onMouseEnter={() => showList()} onMouseLeave={() => hideList()}>
                <span>{this.props.navItemName} <i className="mdi mdi-chevron-down" /></span>
                <div style={{ width: '200%', left: '-50%' }} className={`drop-down ${this.state.show ? 'show' : ''}`} id="header-drop-down-box" onClick={ignoreClick}>
                    <div className='support-icon'><Link className='s-hover support-icon' to='/'>News</Link></div>
                    <div className='support-icon'><Link className='s-hover support-icon' to='/'>Guides</Link></div>
                    <div className='support-icon' style={{ paddingBottom: '15px' }}><Link className='s-hover support-icon' to='/'>Downloads</Link></div>
                </div>
            </div >
        )
    }
}

export default ResourcesDropdown