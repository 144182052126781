import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        const gtag = window.gtag;
        let status = this.props.auth.user?"true":"false"

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH' &&
            typeof(gtag) === 'function') {
            gtag('config', 'UA-2294207-1',{
                'custom_map': {
                'dimension2': {status}
                }
                });
        }
    }

    render () {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

export default withRouter(connect(mapStateToProps,null)(GoogleAnalytics));