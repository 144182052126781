import React, { Fragment } from 'react'
import * as R from 'ramda'
import { Row, Col } from 'reactstrap'
import { getUOMConversionRate, getValidUOM, addDefaultSrc } from '../../utils'
import './Navbar.scss'
import CartHOC from '../Reusable/CartHOC'


const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG, PUBLIC_URL } = process.env
const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG

const NavCartPopoverCard = (props) => {

    // const handleQuantityChange = (e, id) => {
    //     const quantity = e.target.value
    //     props.editQuantityInput({ quantity: Number(quantity), id: id })
    // }


    const { item, isPriceVisible } = props
    const thumbnailImage = R.pathOr(`${PUBLIC_URL}/images/imageComingSoon.png`, ['thumbnailImage'], item)
    const displayName = (R.pathOr('', ['siteDisplayName'], item) || R.pathOr('', ['displayName'], item))
    const price = R.pathOr('', ['price'], item)
    const quantity = R.pathOr(1, ['quantity'], item)
    //const selectedQuantity = R.find(R.propEq('id', item.id))(R.pathOr([], ['quantityInput'], props))
    const unit = R.pathOr(null, ['unit'], item)
    const units = R.pathOr([], ['units'], item)

    const validUOM = units ? getValidUOM(unit, units) : {}
    const selectedUOM = { label: validUOM.unitName, value: validUOM.id }
    const selectedConversionRate = getUOMConversionRate(unit, units)
    const rate = (selectedConversionRate * price).toFixed(2)

    return (
        <Fragment>
            <Row className='nav-popover-card'>
                <Col xs='3' className='nav-popover-img-wrap'>
                    <img onError={addDefaultSrc} alt={`${PUBLIC_URL}/images/imageComingSoon.png`} src={IMAGE_URL + thumbnailImage} alt="thumbnailImage" loading="lazy"/>
                </Col>
                <Col>
                    <div className='nav-popover-name'>{displayName}</div>
                    {isPriceVisible &&
                        <div>
                            <span className='nav-popover-price'>
                                {`$${rate}`}
                            </span>
                        </div>
                    }

                    <div className='nav-popover-quantity'>Quantity: {quantity}  {selectedUOM.label && ' - ' + selectedUOM.label}</div>
                    <span className='nav-popover-item-remove' onClick={() => props.removeCartDetail(item.id)}>Remove</span>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CartHOC(NavCartPopoverCard)
