import React from 'react';

const ProductDetailsInfoBar = ({children }) => {
    return (
        <div className="product-details__info">
            { children }
        </div>
    );
}

export default ProductDetailsInfoBar;
