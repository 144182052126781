import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import myAccount from './myAccountReducer'
import auth from './authReducer'
import cart from './cartReducer'
import loader from './loaderReducer'
import paginate from './paginateReducer'
import list from './listReducer'
import shopping from './shoppingReducer'
import checkout from './checkoutReducer'
import itemList from './itemListReducer'
import transaction from './transactionsReducer'
import payment from './PaymentReducer'
import nav from './navReducer'
import orderApproval from './orderApprovalReducer'
import accountSettings from './accountSettingsReducer'
import punchout from './punchoutReducer'

export default combineReducers({
    auth,
    cart,
    loader,
    myAccount,
    paginate,
    list,
    shopping,
    checkout,
    itemList,
    transaction,
    payment,
    form: formReducer,
    nav,
    orderApproval,
    accountSettings,
    punchout
})
