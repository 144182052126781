import React from 'react';
import bgImage from './images/brochure.png';
import DownloadSVG from '../../shared/icons/DownloadSVG';

const ProductDetailsDownload = ({ src, title }) => {
	return (
		<div className="product-details__download">
			<div className="product-details__download-slide">
				<a href={src} className="product-details__download-link" target="_blank" style={{ backgroundImage: `url(${bgImage})` }}>
					<div className="product-details__download-content">
						<h2 className="product-details__download-title">{title}</h2>
						<div className="product-details__download-wrapper">
							<DownloadSVG className="product-details__download-icon" />
							<p className="product-details__download-text">Download</p>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
};

export default ProductDetailsDownload;
