//import * as R from 'ramda'
import { map, mergeRight, isEmpty } from 'ramda'
import {
    SET_INVOICES,
    SET_CREDIT_TOTALS,
    SET_CREDIT_MEMO,
    SET_UNAPPLIED_PAYMENTS,
    RESET_PAYMENT_PAGE,
    RESET_PAYMENT_PROPS,
    SET_PAYMENT_CREDIT_CARD,
    SET_CVC_CODE,
    SET_PAYMENT_SUBMISSION_DETAILS

} from '../actions/types'

const INITIAL_STATE = {
    invoices: [],
    creditMemos: [],
    unappliedPayments: [],
    totalCredits: {},
    selectedPaymentCreditCard: {},
    paymentSubmissionDetails: {},
}


export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_CREDIT_MEMO:
            return { ...state, creditMemos: map(cm => mergeRight(cm, { maxApply: cm.total - cm.amountPaid, apply: 0, checked: false, errorMessage: '' }), payload || []) }
        case SET_UNAPPLIED_PAYMENTS:
            return { ...state, unappliedPayments: map(up => mergeRight(up, { maxApply: up.unappliedPayment, apply: 0, checked: false, errorMessage: '' }), payload || []) }
        case SET_CREDIT_TOTALS:
            return { ...state, totalCredits: payload }
        case SET_INVOICES:
            return { ...state, invoices: map(inv => mergeRight(inv, { apply: inv.amountDue, checked: false, errorMessage: '' }), payload || []) }
        case RESET_PAYMENT_PAGE:
            return mergeRight(INITIAL_STATE, { paymentSubmissionDetails: state.paymentSubmissionDetails })
        case RESET_PAYMENT_PROPS:
            return { ...state, [payload.reducerProp]: payload.data }
        case SET_PAYMENT_CREDIT_CARD:
            return { ...state, selectedPaymentCreditCard: payload }
        case SET_CVC_CODE:
            return { ...state, selectedPaymentCreditCard: isEmpty(state.selectedPaymentCreditCard) ? state.selectedPaymentCreditCard : mergeRight(state.selectedPaymentCreditCard, { cvcCode: payload }) }
        case SET_PAYMENT_SUBMISSION_DETAILS:
            return { ...state, paymentSubmissionDetails: payload }
        default:
            return state
    }
}