import React from 'react';
import classnames from 'classnames';

const PrescriptionSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 29 32" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M29.243 13.387h-4.251l-5.822 7.179-7.045-8.462c1.074-0.34 1.998-0.884 2.77-1.591l-0.005 0.005c1.378-1.281 2.078-2.752 2.078-4.377s-0.708-3.093-2.109-4.313c-1.293-1.135-2.998-1.828-4.866-1.828-0.034 0-0.068 0-0.102 0.001l0.005-0h-9.896v21.396h3.58v-8.927h4.591l8.829 10.741-7.254 8.79h4.254l5.137-6.227 5.182 6.227h4.245l-7.266-8.859 7.774-9.542zM3.58 3.124h6.315c0.010-0 0.023-0 0.035-0 0.925 0 1.767 0.351 2.403 0.926l-0.003-0.003c0.708 0.61 1.057 1.298 1.057 2.094s-0.342 1.473-1.044 2.078c-0.631 0.566-1.469 0.913-2.388 0.913-0.019 0-0.037-0-0.056-0l0.003 0h-6.322z"></path>
		</svg>
	);
};

export default PrescriptionSVG;
