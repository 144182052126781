import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { pathOr, isEmpty, isNil, either } from 'ramda';
import CartHOC from '../Reusable/CartHOC';
import DealmedPopover from '../../shared/components/DealmedPopover';
import { TabletAndSmaller, Desktop } from '../../shared/responsive-breakpoints';
import Spinner from '../../shared/components/Spinner';
import ShoppingBagSVG from '../../shared/icons/ShoppingBagSVG';
import mixpanel from 'mixpanel-browser'
import { connect } from 'react-redux';
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent';
import NotificationBellSVG from '../../shared/icons/NotificationBellSVG';
import { createNotificationMessage } from '../../utils/createNotificationMessage';
import { createNotificationLink } from '../../utils/createNotificationLink';
import { getUserNotifications, markNotificationsAsRead } from '../../actions';


const NotificationPopover = (props) => {
	const { isOpen, openPopover, closePopover, className, cartLoading, cartDetail, isPriceVisible, orderSummaryDisplay, isPunchout, auth } = props;
    const [notifications, setNotifications] = useState([])
    const sortedNotifications = notifications?.sort((a, b) => b.id - a.id);
    const totalUnreadNotifications = notifications?.filter(notification => notification?.userNotificationStatus?.unread)?.length

	const triggerRef = useRef(null);
	const popoverWrapperRef = useRef(null);
	const popoverMatchWidthRef = useRef(null);

	const handleClick = () => {
		if (isOpen) {
			closePopover();
		} else {
			openPopover();
            totalUnreadNotifications > 0 && markNotificationsAsRead()
		}
	};

	const logClickEvent = ( {eventType} ) => {
		mixpanelLogEvent({ 
			eventType, 
			userDetails:props?.auth?.user, 
			metaData:{
				cartDetails: props.cartDetail || [],
				currentPage: props.location?.pathname
			} 
		  })
	  }

      const NotificationCard = ({ notification }) => {
            const message = createNotificationMessage(notification)
            const linkDestination = createNotificationLink(notification)


        return(
            <div className='dealmed-header__notification-card'>
                <a href={linkDestination} onClick={closePopover}>
                        {message}
                </a>
                <div className="dealmed-header__notification-card__dot-div">
                    <p className="dealmed-header__notification-card__dot" style={{color: notification.userNotificationStatus.unread ? 'green' : 'white'}}>•</p>
                </div>
            </div>
        )
      }

	const isNullOrEmpty = either(isNil, isEmpty);

	const mobileNotificationPopover = (
		<Link to="/cart" className="dealmed-header__cart-link">
			<span className="visually-hidden">Shopping Cart</span>
			<span className="dealmed-header__icon-wrapper">
				<NotificationBellSVG className="dealmed-header__cart-icon"/>
				{totalUnreadNotifications !== 0 ? <span className="dealmed-header__cart-items">{totalUnreadNotifications}</span> : null}
			</span>
		</Link>
	);

	const desktopNotificationPopover = (
		<>
			<button type="button" ref={triggerRef} className="dealmed-header__cart-toggle-btn" onClick={handleClick}>
				<span className="visually-hidden">Shopping Cart</span>
				<span className="dealmed-header__icon-wrapper">
					<NotificationBellSVG className="dealmed-header__cart-icon" />
					{totalUnreadNotifications !== 0 ? <span className="dealmed-header__cart-items">{totalUnreadNotifications}</span> : null}
				</span>
			</button>

			{isOpen && popoverMatchWidthRef.current && (
				<DealmedPopover
					innerRef={popoverWrapperRef}
					targetRef={triggerRef}
					closePopover={closePopover}
					position={popoverMatchWidthRef.current}
					className={className}
				>
					{cartLoading || isNullOrEmpty(notifications) ? (
						cartLoading ? (
							<Spinner />
						) : (
							<p className="dealmed-header__notification-empty">No Notifications</p>
						)
					) : (
						<>
							<div className="dealmed-header__notification-header">
								<h4 className="dealmed-header__notification-header__text">Notifications</h4>
							</div>
							<ul className="dealmed-header__notification-list">
								{sortedNotifications.map((notification, index) => {
									return (
										<li key={index} className="dealmed-header__notification-item">
											<NotificationCard notification={notification} />
										</li>
									);
								})}
							</ul>
						</>
					)}
				</DealmedPopover>
			)}
		</>
	);

	useEffect(() => {
		import('@reach/popover').then((module) => {
			popoverMatchWidthRef.current = module.positionMatchWidth;
		});
	}, []);

    useEffect(() => {
        const getCurrentNotifications = async () => {
            const currentNotifications = await getUserNotifications()
            setNotifications(currentNotifications.data) //update notifications whenever the page is changed
        }
      auth?.user?.id && getCurrentNotifications() //only get notifications if logged in
      },[props.location.pathname])
    

	return (
		<React.Suspense fallback={<Spinner />}>
			<TabletAndSmaller>{mobileNotificationPopover}</TabletAndSmaller>
			<Desktop>{desktopNotificationPopover}</Desktop>
		</React.Suspense>
	);
};

const mapStateToProps = state => {
	return {
	  auth: state.auth,
	  categories: state.shopping.categories,
	}
  }

export default connect(mapStateToProps)(CartHOC(NotificationPopover))
