import React from 'react';

const ProductDetailsTabsNavigationItem = React.memo(({ path, text }) => {
    return (
        <li className="product-details__tabs-item">
            <a href={ path } className="product-details__tabs-link">
                <h2 className="product-details__tabs-text">
                    { text }
                </h2>
            </a>
        </li>
    );
});

export default ProductDetailsTabsNavigationItem;
