import React, { Component } from 'react'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { setCategories, getSellableControlledSubstances, getItemLists, setCartDetails, setSaveForLater, clearCartDetails, setCurrentUser, setRelatedAccounts, setAccountLoadingFalse, hideFooterTop } from '../../actions'

export default (ComposedComponent) => {

    class InitializeHOC extends Component {

        initAuthData = () => {
            const { getSellableControlledSubstances, getItemLists, setCartDetails, setSaveForLater, setRelatedAccounts } = this.props
            getSellableControlledSubstances()
            getItemLists()
            setCartDetails()
            setSaveForLater()
            setRelatedAccounts()
        }

        initNonAuthData = () => {
            const { setCategories } = this.props
            setCategories()
        }

        cleanupOnLogout = () => {
            const { clearCartDetails } = this.props
            clearCartDetails()
        }


        render() {
            const props = {
                initAuthData: this.initAuthData,
                initNonAuthData: this.initNonAuthData,
                cleanupOnLogout: this.cleanupOnLogout,
                isAuthenticated: this.props.isAuthenticated,
                hideNavbar: this.props.hideNavbar,
                hideFooterTop: this.props.hideFooterTop
            }

            return <ComposedComponent {...{ ...this.props, ...props }} />
        }
    }

    const mapStateToProps = (state) => {
        return {
            isValidToken: state.auth.isValidToken,
            isAuthenticated: pathOr(false, ['user', 'id'], state.auth),
            hideNavbar: state.nav.hideNavbar,
            hideFooterTop: state.nav.hideFooterTop,
            userEmail: state.auth?.user?.email
        }
    }

    return connect(mapStateToProps,
        { setCategories, getSellableControlledSubstances, getItemLists, setCartDetails, setSaveForLater, clearCartDetails, setCurrentUser, setRelatedAccounts, setAccountLoadingFalse }
    )(InitializeHOC)
}
