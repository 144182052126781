import React from 'react';
import classnames from 'classnames';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';

const DealmedDialog = ({ className, isOpen, label, onDismiss, children }) => {
	return (
		<DialogOverlay className={classnames('dealmed-dialog', className)} isOpen={isOpen} onDismiss={onDismiss}>
			<DialogContent className="dealmed-dialog__content" aria-label={label}>
				{children}
			</DialogContent>
		</DialogOverlay>
	);
};

export default DealmedDialog;
