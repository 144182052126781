import React from 'react';
import { Link } from 'react-router-dom';
import { addDefaultSrc } from '../../utils'
const { PUBLIC_URL } = process.env

const ProductDetailsRelatedProduct = React.memo(({ path, productImageSrc, productManufacturer, productName }) => {
    return (
        <div className="product-details__related-product">
            <div className="product-details__related-product-slide">
                <Link to={ path } className="product-details__related-product-link">
                    <div className="product-details__related-product-image-wrapper">
                        <img src={ productImageSrc || `${PUBLIC_URL}/Images/imageComingSoon.png`} className="product-details__related-product-image" onError={ addDefaultSrc } alt="Related Product" loading="lazy"/>
                    </div>
                    <div className="product-details__related-product-content">
                        <p className="product-details__related-product-manufacturer">{ productManufacturer }</p>
                        <p className="product-details__related-product-name">{ productName }</p>
                    </div>
                </Link>
            </div>
        </div>
    );
});

export default ProductDetailsRelatedProduct;
