
import { resolveReq, axios } from '../utils/api'
import {
  SET_TERM_DETAILS,
  START_LOADER,
  STOP_LOADER,
  SET_SUBMISSION_DETAILS,
  SET_CHECKOUT_ERROR_MESSAGES,
  SET_STATUSES,
  HIDE_NAVBAR,
  SHOW_NAVBAR,
  HIDE_FOOTER,
  SHOW_FOOTER
} from './types'



// eslint-disable-next-line import/prefer-default-export
export const setTermDetails = () => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.get(`/api/checkout/checkout/getCheckoutData`))
    if (error) // eslint-disable-next-line no-console
      return console.error(error)
    const { hasTerms, terms, availableBalance } = data
    dispatch({ type: SET_TERM_DETAILS, payload: { hasTerms, terms, availableBalance } })

    return { hasTerms, terms, availableBalance }
  }
}

export const setStatuses = () => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.get(`/api/checkout/checkout/getTypes`))
    if (error) // eslint-disable-next-line no-console
      return console.error(error)
    dispatch({ type: SET_STATUSES, payload: data })
  }
}

export const setUPSAlternateShippingData = (body) => {
  return resolveReq(axios.get(`/api/checkout/checkout/getAlternateShippingData?numShipDates=${body.numShipDates}&shipAddressZip=${body.shipAddressZip}&shipCity=${body.shipCity}`))
}


export const setCheckoutErrorMessage = (data) => {
  return { type: SET_CHECKOUT_ERROR_MESSAGES, payload: data }
}

export const submitReturnCart = () => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'processingOrderLoading' })
    const { data, error } = await resolveReq(axios.post(`/api/checkout/checkout/returncart`))
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
    } else {
      dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
      return data
    }
  }
}

export const submitOrderForm = (body) => {
  return async dispatch => {
    dispatch({ type: START_LOADER, payload: 'processingOrderLoading' })
    const { data, error } = await resolveReq(axios.post(`/api/checkout/checkout/placeOrder`, body))
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
    } else {
      const { orderConfirmation, status = '', subscriptionItemList={} } = data
      dispatch({ type: SET_SUBMISSION_DETAILS, payload: { orderConfirmation, status } })
      dispatch({ type: STOP_LOADER, payload: 'processingOrderLoading' })
      return data
    }
  }
}

export const hideNavbar = () => {
  return { type: HIDE_NAVBAR }
}

export const showNavbar = () => {
  return { type: SHOW_NAVBAR }
}

export const hideFooterTop = () => {
  return { type: HIDE_FOOTER }
}

export const showFooterTop = () => {
  return { type: SHOW_FOOTER }
}
