import mixpanel from 'mixpanel-browser'
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)


export const mixpanelLogEvent = ({ eventType, itemDetails={}, userDetails={}, metaData = {} }) => {
    const insertId = `${eventType}-${new Date().toISOString()}-${Math.random().toString(36).substr(2, 9)}`

    if(process.env.NODE_ENV === 'production'){
        mixpanel.track(eventType, {
            item: itemDetails,
            user: userDetails,
            metaData,
            insert_id: insertId
        })
    }
}
