import _ from 'lodash'
import { resolveReq, axios } from '../utils/api'
import {
  TOGGLE_MENU_DROPDOWN,
  TOGGLE_HAMBURGER_MENU,
  SET_ACTIVE_MENU,
  SET_USER_NAME_AVAILABILITY,
  SET_SALESREP,
  SET_OVERVIEW_DATA,
  SET_SALES_ORDERS,
  STOP_LOADER,
  START_LOADER,
  SET_IS_SIDEBAR_COLLAPSED,
} from './types'

export const toggleMenu = menuName => {
  return dispatch => {
    dispatch({ type: TOGGLE_MENU_DROPDOWN, payload: menuName })
    dispatch({ type: SET_ACTIVE_MENU, payload: menuName })
  }
}

export const setActiveMenuItem = menuName => {
  return dispatch => {
    dispatch({ type: SET_ACTIVE_MENU, payload: menuName })
  }
}

export const toggleHamburgerMenu = () => {
  return { type: TOGGLE_HAMBURGER_MENU }
}

export const setUsernameAvailable = (username, id = null) => {
  return async dispatch => {
    dispatch({ type: SET_USER_NAME_AVAILABILITY, payload: 'loading' })
    const { data, error } = await resolveReq(
      axios.post('/api/myaccount/contact/isusernameavailable', { username, id }),
    )
    if (error) {
      return console.log(error)
    }
    dispatch({ type: SET_USER_NAME_AVAILABILITY, payload: data })
  }
}

export const setSalesRep = () => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.get('/api/myaccount/contact/getSalesRep'))
    if (error) {
      return console.log(error)
    }
    dispatch({ type: SET_SALESREP, payload: data })
  }
}

export const setOverviewData = () => {
  return async dispatch => {
    const { data, error } = await resolveReq(axios.get('/api/myaccount/account/getOverViewData'))
    if (error) {
      return console.log(error)
    }
    dispatch({ type: SET_OVERVIEW_DATA, payload: data })
  }
}

export const getSalesOrders = ({ shouldShowCustomStatuses, isParentAccount }) => {

  return async dispatch => {

    dispatch({ type: START_LOADER, payload: 'isSalesOrdersLoading' })

    const route = isParentAccount ? 'salesordersForParent' : 'salesorders'

    const { data, error } = await resolveReq(axios.get(`/api/myaccount/transactions/${route}`))

    if (error) {
      return console.log(error)
    }

    const salesOrdersWithNewStatuses = (dataToClone) => {
      return _.cloneDeep(dataToClone).map(order => {
        if (order.status === 'Partially Billed') order.status = 'Partially Fulfilled'
        if (order.status === 'Billed') order.status = 'Fulfilled'
        return order
      })
    }

    const salesOrdersToDisplay = shouldShowCustomStatuses ? salesOrdersWithNewStatuses(data.results) : data.results

    dispatch({ type: SET_SALES_ORDERS, payload: salesOrdersToDisplay })
    dispatch({ type: STOP_LOADER, payload: 'isSalesOrdersLoading' })
  }
}

export const setIsSidebarCollapsed = toCollapse => {
  return { type: SET_IS_SIDEBAR_COLLAPSED, payload: toCollapse }
}

export const getProofOfDelivery = async (orderNumber) => {
  const response = await resolveReq(axios.get(`/api/myaccount/transactions/salesorders/pod?orderNumber=${orderNumber}`));
  return response.data.proofOfDelivery;
}

export const getFulfillmentTrackingData = async (trackingNumber) => {
  const response = await resolveReq(axios.get(`/api/myaccount/transactions/trackingData?trackingNumber=${trackingNumber}`));
  return response;
}

export const getRecentFulfillments = async (accountId) => {
  const response = await resolveReq(axios.get(`/api/myaccount/transactions/recentFulfillments?account=${accountId}`));
  return response;
}

export const getQuickOrderData = async () => {
  const response = await resolveReq(axios.get('/api/myaccount/transactions/quickOrderProducts'));
  return response;
}

export const getRecentUserSearchData = async () => {
  const response = await resolveReq(axios.get('/api/myaccount/transactions/recentUserSearchProducts'));
  return response;
}

export const markNotificationsAsRead = async () => {
  const response = await resolveReq(axios.get('/api/myaccount/transactions/markNotificationsAsRead'));
  return response;
}

export const getUserNotifications = async () => {
  const response = await resolveReq(axios.get('/api/myaccount/transactions/getUserNotifications'));
  return response;
}