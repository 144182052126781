import React from 'react'
import { includes, type, isEmpty, trim, compose, map, filter, split } from 'ramda'

export const validateParams = (required, params = {}) => {
    var missing = required.reduce(function (p, q) {
        if (!params[q] || ((type(params[q] === 'Array') || (type(params[q] === 'Object'))) && isEmpty(params[q])))
            return p.concat([q])
        else return p
    }, [])
    if (missing.length > 0)
        return missing.join(', ')
    else return ''
}

export const required = value => value ? undefined : 'Required'

export const hasSpaces = value => value && includes((' '), value) ? `Cannot contain spaces` : undefined

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined

export const passwordsMustMatch = (value, allValues) =>
    value !== allValues.password ? 'Passwords do not match' : undefined

export const minLength = min => value =>
    value && value.length < min ? `Must be at least ${min} characters` : undefined

export const isStrong = value => value && value.length < 8 ?
    `Must be at least 8 characters`
    :
    value && !(/\d/.test(value)) ?
        `Must contain a number`
        :
        value && !/[~`!#$%@\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value) ? `Must contain a special character`
            :
            undefined

export const isNumber = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined

export const emailValid = value =>
    value && isEmailValid(value) ? undefined : 'Invalid email address'

export const emailsCommaSeparatedValid = value => {
    const invalidEmails = compose(
        filter(e => !e),
        map(e => isEmailValid(trim(e || ''))),
        split(',')
    )(value || '')

    return isEmpty(invalidEmails)
}


export const reduxValidationField = ({ meta: { touched, error, warning } }) => (
    <div>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)
// export const aol = value =>
//     value && /.+@aol\.com/.test(value) ?
//         'Really? You still use AOL for your email?' : undefined

export const hasDecimal = (num) => {
    if (typeof (num) !== 'number' || isNaN(Number(num)))
        return "Only numbers can be entered"

    return num - Math.floor(num) !== 0 ? "Decimals not allowed" : undefined
}

export const isEmailValid = (email) => {
    return Boolean(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
}