import React from 'react';

const ProductDetailsBox = ({ children }) => {
    return (
        <div className="product-details__box">
            { children }
        </div>
    );
}

export default ProductDetailsBox;
