import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

const { PUBLIC_URL } = process.env

const CheckoutNavbar = () => (
  <Row className="checkout-navbar">
    <Col xs="12" sm="6">
      <Link to="/cart">
        <img
          src={`${PUBLIC_URL}/images/dealmed_logo_mobile.png`}
          width="150"
          alt="Dealmed Logo"
          loading="lazy"
        />
      </Link>
    </Col>
    <Col xs="6" className="d-none d-sm-flex justify-content-end align-items-center">
      <div>1-800-569-0570</div>
    </Col>
  </Row>
)

export default CheckoutNavbar
