import React from 'react';
import classnames from 'classnames';

const DownChevronSVG = ({ className }) => {
	return (
		<svg viewBox="0 0 32 32" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M15.433 22.163c0.313 0.316 0.82 0.316 1.133 0l9.865-9.856c0.313-0.316 0.313-0.826 0-1.141l-1.32-1.329c-0.313-0.316-0.82-0.316-1.133 0l-7.979 7.956-7.979-7.956c-0.313-0.316-0.82-0.316-1.133 0l-1.32 1.329c-0.313 0.316-0.313 0.826 0 1.141l9.865 9.856z"></path>
		</svg>
	);
};

export default DownChevronSVG;
